import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Components/layouts/site/header/header.component';
import { FooterComponent } from './Components/layouts/site/footer/footer.component';
import { HomeComponent } from './Components/pages/home/home.component';
import { AboutComponent } from './Components/pages/about/about.component';
import { PagenotfoundComponent } from './Components/pages/pagenotfound/pagenotfound.component';
import { FindJobsComponent } from './Components/pages/find-jobs/find-jobs.component';
import { FindCandidatesComponent } from './Components/pages/find-candidates/find-candidates.component';
import { ResourcesComponent } from './Components/pages/resources/resources.component';
import { ContactComponent } from './Components/pages/contact/contact.component';
import { LoginComponent } from './Components/pages/login/login.component';
import { RegisterComponent } from './Components/pages/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatTabsModule } from '@angular/material/tabs';
import { SidemenuComponent } from './Components/layouts/admin/sidemenu/sidemenu.component';
import { AdminlayoutComponent } from './Components/layouts/admin/adminlayout/adminlayout.component';
import { SitelayoutComponent } from './Components/layouts/site/sitelayout/sitelayout.component';
import { AdminheaderComponent } from './Components/layouts/admin/adminheader/adminheader.component';
import { AdminfooterComponent } from './Components/layouts/admin/adminfooter/adminfooter.component'
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip';
import { ForgetPasswordComponent } from './Components/pages/forget-password/forget-password.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatPaginatorModule } from '@angular/material/paginator'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helper/jwt.interceptor';
import { DatePipe } from '@angular/common';
import { FaqsComponent } from './Components/pages/faqs/faqs.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { FileDragNDropDirective } from './_helper/file-drag-n-drop.directive';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RecordingComponent } from './Components/pages/recording/recording.component';
import {MatCardModule} from '@angular/material/card';
import { InstructionsComponent } from './Components/pages/instructions/instructions.component';
import { ThankyouComponent } from './Components/pages/thankyou/thankyou.component';
import { PartnerfooterComponent } from './Components/layouts/partner/partnerfooter/partnerfooter.component';
import { PartnerheaderComponent } from './Components/layouts/partner/partnerheader/partnerheader.component';
import { PartnerlayoutComponent } from './Components/layouts/partner/partnerlayout/partnerlayout.component';
import { PartnersidemenuComponent } from './Components/layouts/partner/partnersidemenu/partnersidemenu.component';
import {  MatChipsModule } from '@angular/material/chips';
import { ConferenceComponent } from './Components/pages/conference/conference.component';
import { UserNotificationComponent } from './Components/shared/user-notification/user-notification.component';
import { NotificationHub } from './_services/notification.hub.service';
import { SSOLoginComponent } from './Components/pages/sso-login/sso-login.component';
import { SearchToolbarModule } from './Components/pages/searchtoolbar/searchtoolbar.module';
import { PrivacyPolicyComponent } from './Components/pages/privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './Components/pages/term-condition/term-condition.component';
import { Homev2Component } from './Components/pages/homev2/homev2.component';
import { FullStackDeveloperComponent } from './Components/pages/full-stack-developer/full-stack-developer.component';
import { CampaignComponent } from './Components/pages/campaign/campaign.component';
import { ContactDialog } from './Components/shared/contact-dialog/contact-dialog-component';
import { PythonDeveloperComponent } from './Components/pages/python-developer/python-developer.component';
import { MobileAppDeveloperComponent } from './Components/pages/mobile-app-developer/mobile-app-developer.component';
import { SoftwareDeveloperComponent } from './Components/pages/software-developer/software-developer.component';
import { JavaDeveloperComponent } from './Components/pages/java-developer/java-developer.component';
import { DotNetDeveloperComponent } from './Components/pages/dot-net-developer/dot-net-developer.component';
import { MobileAppDeveloperCampaignComponent } from './Components/pages/mobile-app-developer-campaign/mobile-app-developer-campaign.component';
import { SoftwareDeveloperCampaignComponent } from './Components/pages/software-developer-campaign/software-developer-campaign.component';
import { PythonDeveloperCampaignComponent } from './Components/pages/python-developer-campaign/python-developer-campaign.component';
import { JavaDeveloperCampaignComponent } from './Components/pages/java-developer-campaign/java-developer-campaign.component';
import { DotNetDeveloperCampaignComponent } from './Components/pages/dot-net-developer-campaign/dot-net-developer-campaign.component';
import { FullStackDeveloperCampaignComponent } from './Components/pages/full-stack-developer-campaign/full-stack-developer-campaign.component';
import { RoutePartsService } from './_services/route-parts.service';
import { AngularDeveloperComponent } from './Components/pages/angular-developer/angular-developer.component';
import { AngularDeveloperCampaignComponent } from './Components/pages/angular-developer-campaign/angular-developer-campaign.component';
import { MeanStackDeveloperComponent } from './Components/pages/mean-stack-developer/mean-stack-developer.component';
import { MeanStackDeveloperCampaignComponent } from './Components/pages/mean-stack-developer-campaign/mean-stack-developer-campaign.component';
import { ReactJsDeveloperComponent } from './Components/pages/react-js-developer/react-js-developer.component';
import { ReactJsDeveloperCampaignComponent } from './Components/pages/react-js-developer-campaign/react-js-developer-campaign.component';
import { MernStackDeveloperComponent } from './Components/pages/mern-stack-developer/mern-stack-developer.component';
import { MernStackDeveloperCampaignComponent } from './Components/pages/mern-stack-developer-campaign/mern-stack-developer-campaign.component';
import { PromotionComponent } from './Components/pages/promotion/promotion.component';
import { ApplyMatchedCandidateComponent } from './Components/pages/apply-matched-candidate/apply-matched-candidate.component';
import { FindApplyJobComponent } from './Components/pages/find-apply-job/find-apply-job.component';
import { ApplyJobDialogComponent } from './Components/pages/find-apply-job/apply-job-dialog/apply-job-dialog.component';
import { StarRatingComponent } from './Components/pages/star-rating/star-rating.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    HomeComponent,
    PagenotfoundComponent,
    FindJobsComponent,
    FindCandidatesComponent,
    ResourcesComponent,
    ContactComponent,
    LoginComponent,
    RegisterComponent,
    SidemenuComponent,
    AdminlayoutComponent,
    SitelayoutComponent,
    AdminheaderComponent,
    AdminfooterComponent,
    ForgetPasswordComponent,
    FaqsComponent,
    FileDragNDropDirective,
    RecordingComponent,
    InstructionsComponent,
    ThankyouComponent,
    PartnerfooterComponent,
    PartnerheaderComponent,
    PartnerlayoutComponent,
    PartnersidemenuComponent,
    ConferenceComponent,
    UserNotificationComponent,
    SSOLoginComponent,
    PrivacyPolicyComponent,
    TermConditionComponent,
    Homev2Component,
    FullStackDeveloperComponent,
    CampaignComponent,   
    ContactDialog,
    PythonDeveloperComponent,
    MobileAppDeveloperComponent,
    SoftwareDeveloperComponent,
    JavaDeveloperComponent,
    DotNetDeveloperComponent,
    MobileAppDeveloperCampaignComponent,
    SoftwareDeveloperCampaignComponent,
    PythonDeveloperCampaignComponent,
    JavaDeveloperCampaignComponent,
    DotNetDeveloperCampaignComponent,
    FullStackDeveloperCampaignComponent,
    AngularDeveloperComponent,
    AngularDeveloperCampaignComponent,
    MeanStackDeveloperComponent,
    MeanStackDeveloperCampaignComponent,
    ReactJsDeveloperComponent,
    ReactJsDeveloperCampaignComponent,
    MernStackDeveloperComponent,
    MernStackDeveloperCampaignComponent,
    PromotionComponent,
    ApplyMatchedCandidateComponent,
    FindApplyJobComponent,
    ApplyJobDialogComponent,
    StarRatingComponent
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule ,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    BrowserAnimationsModule, // required animations module
   
    ToastrModule.forRoot({
      closeButton:true,
      progressBar:true,
      progressAnimation:'decreasing'
  }),
    CarouselModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatTableModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDialogModule,
    MatNativeDateModule,
    MatIconModule,
    MatRadioModule ,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatCardModule,
    NgxWebstorageModule.forRoot(),
    SearchToolbarModule
    
     ],
  providers: [
    RoutePartsService,
    NotificationHub,
  {
    provide: APP_INITIALIZER,
    useFactory: (notificationHub: NotificationHub) => () => notificationHub.startConnection(),
    deps: [NotificationHub],
    multi: true,
  },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },DatePipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },],
  bootstrap: [AppComponent]
})
export class AppModule { }
