import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Search } from 'src/app/_models/candidates';

@Component({
  selector: 'app-homev2',
  templateUrl: './homev2.component.html',
  styleUrls: ['./homev2.component.css']
})
export class Homev2Component implements OnInit {

  constructor(private sessionService:LocalStorageService) { 
    this.sessionService.store('candidateFilter', new Search()); 
    this.sessionService.store('jobFilter', new Search());
  }

  ngOnInit(): void {
  }

}
