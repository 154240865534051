import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute  }  from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
import { BaseComponent } from 'src/app/Components/shared/base-component/base.component';
import { APIConfig } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_config/api.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit {
hideControl=environment.hideControl;
  isActive = false;
  isOpen = false;
  userId: any = 0;
  userName: any;
  userDetails:any;
  siteLogo=environment.homelogoUrl;
  signupDisabled=!environment.hideControl;
  homePageUrl=environment.defaultPageUrl;
  currectRole='';
  toggleClasses() {
    this.isActive = !this.isActive;
    this.isOpen = !this.isOpen;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  constructor(public router: Router,private activateRoute:ActivatedRoute, private renderer: Renderer2,  sessionService: LocalStorageService,private apiService :ApiService) {

    super(sessionService);
       
   }
  logOut(){
    const userDetail = this.sessionService.retrieve('userDetail');
    this.apiService._get(APIConfig.logout,{userId:userDetail.Data.userDetails.id}).subscribe(()=>{});
    this.sessionService.clear();
    this.userId=0;
  }
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
    this.userDetails = this.sessionService.retrieve('userDetail');

    if (this.userDetails != null && this.userDetails.Data != undefined) {

      this.userId = this.userDetails.Data?.userDetails?.id;
      this.userName=this.userDetails.Data?.userDetails?.firstName+' '+this.userDetails.Data?.userDetails?.lastName ;
    }

    this.currectRole =  this.userDetail?.role?.toLowerCase()
  }

  @HostListener('window:scroll', [])
  onScroll() {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      this.renderer.addClass(document.body, 'affix');
    } else {
      this.renderer.removeClass(document.body, 'affix');
    }
  }

  dashboardClick() {
    try {
     // this.router.navigate(['/client/dashboard']);
     this.router.navigate(['/' + this.currentModuleName + '/dashboard']);
    } catch (error) {
      console.log(error);
    }
   
   // this.router.navigate([route]);
    // if (role.find((x: any) => x.code == "Admin")) {
    //   this.router.navigate(['/admin/dashboard']);
    // }else if(role.find((x: any) => x.code == "Client")){
    //   this.router.navigate(['/client/dashboard']);
    // }
    // else {
    //   this.router.navigate(['/dashboard']);

    // }
  }
}
