import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextToSpeechService {

    private synth: SpeechSynthesis;

    constructor() {
        this.synth = window.speechSynthesis;
    }
    private voices: SpeechSynthesisVoice[] = [];
    speak(text: string, lang: string = 'en-IN', onEndCallback?: () => void): void {
        if (this.synth.speaking) {
            console.error('SpeechSynthesis is already speaking.');
            return;
        }

        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = lang;
        // Select a female voice, filtering by language and possibly voice name
        const femaleVoice = this.voices.find(voice =>
            voice.lang.startsWith(lang) &&
            (voice.name.toLowerCase().includes('female') || voice.name.toLowerCase().includes('woman'))
        );

        if (femaleVoice) {
            utterance.voice = femaleVoice;
        }

        if (onEndCallback) {
            utterance.onend = () => onEndCallback();
        }

        this.synth.speak(utterance);
    }

    stop(): void {
        if (this.synth.speaking) {
            this.synth.cancel();
        }
    }
}
