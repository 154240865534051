<!-- <section class="tolquestions">
    Thank you for sumbit the information.
    </section>

 -->

     <div class="thank-you-container">
         <div class="message-box">
             <h1>Thank You!</h1>
             <p>Your interview is complete.</p>
             <p>We appreciate your time and effort. Our team will review your responses and get back to you shortly.</p>
             <p>If you have any questions, feel free to contact us at <a href="{{contactEmail}}">{{contactEmail}}</a>.</p>
             <!-- <a href="/" class="back-home-btn">Back to Home</a> -->
         </div>
     </div>
 
 