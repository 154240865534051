// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class SpeechRecognitionService {
//   private recognition: any | undefined;
//   private isRecording = false;
//   public transcript = '';
//   private interimTranscript = '';
//   private accumulatedTranscript = '';

//   constructor() {
//     if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
//       this.recognition = 'webkitSpeechRecognition' in window
//         ? new (window as any).webkitSpeechRecognition()
//         : new (window as any).SpeechRecognition();

//       this.recognition.lang = 'en-US';
//       this.recognition.interimResults = true; // Enable interim results
//       this.recognition.maxAlternatives = 1;

//       this.recognition.onresult = (event: any) => {
//         this.interimTranscript = '';
//         let newFinalTranscript = '';
//         for (let i = event.resultIndex; i < event.results.length; i++) {
//           const result = event.results[i];
//           if (result.isFinal) {
//             newFinalTranscript += result[0].transcript + ' ';
//           } else {
//             this.interimTranscript += result[0].transcript;
//           }
//         }
//         // Update the accumulated transcript with new final transcript
//         this.accumulatedTranscript += newFinalTranscript;
//         this.transcript = this.accumulatedTranscript + this.interimTranscript;
//       };

//       this.recognition.onend = () => {
//         if (this.isRecording) {
//           this.recognition?.start(); // Restart recognition if it stops unexpectedly
//         }
//       };

//       this.recognition.onerror = (event: any) => {
//         console.error('Speech recognition error:', event.error);
//         this.isRecording = false;
//       };
//     } else {
//       console.error('Speech recognition not supported in this browser.');
//     }
//   }

//   startRecording(): void {
//     if (this.recognition && !this.isRecording) {
//       this.isRecording = true;
//       this.recognition.start();
//     }
//   }

//   stopRecording(): void {
//     if (this.recognition && this.isRecording) {
//       this.isRecording = false;
//       this.recognition.stop();
//     }
//   }
// }




import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpeechRecognitionService {
  private recognition: any | undefined;
  private isRecording = false;
  public transcript = '';
  private interimTranscript = '';
  private accumulatedTranscript = '';

  constructor() {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      this.recognition = 'webkitSpeechRecognition' in window
        ? new (window as any).webkitSpeechRecognition()
        : new (window as any).SpeechRecognition();

      this.recognition.lang = 'en-US';
      this.recognition.interimResults = true; // Enable interim results
      this.recognition.maxAlternatives = 1;

      this.recognition.onresult = (event: any) => {
        this.interimTranscript = '';
        let newFinalTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const result = event.results[i];
          if (result.isFinal) {
            newFinalTranscript += result[0].transcript + ' ';
          } else {
            this.interimTranscript += result[0].transcript;
          }
        }
        // Update the accumulated transcript with new final transcript
        this.accumulatedTranscript += newFinalTranscript;
        this.transcript = this.accumulatedTranscript + this.interimTranscript;
      };

      this.recognition.onend = () => {
        if (this.isRecording) {
          this.recognition?.start(); // Restart recognition if it stops unexpectedly
        }
      };

      this.recognition.onerror = (event: any) => {
        console.error('Speech recognition error:', event.error);
        this.isRecording = false;
      };
    } else {
      console.error('Speech recognition not supported in this browser.');
    }
  }

  startRecording(): void {
    if (this.recognition && !this.isRecording) {
      this.isRecording = true;
      this.resetTranscript(); // Clear the transcript when starting a new recording
      this.recognition.start();
    }
  }

  stopRecording(): void {
    if (this.recognition && this.isRecording) {
      this.isRecording = false;
      this.recognition.stop();
      this.resetTranscript(); // Optionally clear the accumulated transcript
    }
  }

  private resetTranscript(): void {
    this.accumulatedTranscript = '';
    this.interimTranscript = '';
    this.transcript = '';
  }
}
