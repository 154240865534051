import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from 'src/app/_services/candidate.service';
import { TextToSpeechService } from 'src/app/_services/TextToSpeechService';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {
  roomId: any;
  welcomeMessage: string = `Hey, good morning, and welcome to this online AI-based interview session. We are delighted to have you with us today and appreciate your time and interest in being part of this innovative interview process.

Before we begin, please carefully read the instructions below. Once you're ready, click the button to start your recorded interview.
  I wish you the best of luck during this interview.`;

  constructor(private router : Router,private _Activatedroute: ActivatedRoute,private textToSpeechService: TextToSpeechService,
     private candidateService:CandidateService) { }

  ngOnInit(): void {
    this._Activatedroute.params.subscribe(params => {
      this.roomId = params['roomId'];
      this.textToSpeechService.speak(this.welcomeMessage, 'en-US', () => {
        //this.welcomeMessage='';
       //this.textToSpeechService.stop();
      })
   })


  }


  Recording(){
    this.router.navigate(['/recording',this.roomId]);
  }
}
