import { Component, HostListener, OnInit, VERSION } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CandidateService } from 'src/app/_services/candidate.service';
import { Meeting } from '../../../../app/_models/meeting';
import { SpeechRecognitionService } from 'src/app/_services/SpeechRecognitionService';
import { TextToSpeechService } from 'src/app/_services/TextToSpeechService';

declare var EnxRtc: any;
declare var $: any;
@Component({
  selector: 'app-recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.css']
})
export class RecordingComponent implements OnInit {
  roomId: any;
userRecordingArray=[];
  candidateData: any;
  questionList: any;
  candidateDetails: any;
  question: any;
  totalQuestion: number = 0;
  currentQuestion: number = 1;
  isSubmit: boolean = false;
  isLoading: boolean = true;
  questionAnswers: any;
  start: Date = new Date();
  answer: any;
  answerModel: any;
  version='';
isAnsDoneForAllQuestion=false;
transInterval:any;
  transcript='';
  feedbackRating=0;
  constructor(private router: Router, private _Activatedroute: ActivatedRoute,
    private candidateService: CandidateService, private toastrService: ToastrService,private speechRecognitionService: SpeechRecognitionService,private textToSpeechService:TextToSpeechService) { 
      this.speechRecognitionService.transcript = '';
      this.version = 'Angular ' + VERSION.major;
     
    this.transInterval=  setInterval(() => {   
        this.transcript = this.speechRecognitionService.transcript;
        if(this.userRecordingArray[this.currentQuestion-1])
        this.userRecordingArray[this.currentQuestion-1].answer= this.transcript;
      }, 10); 
}

onRatingUpdated(rating: number) {

   this.feedbackRating=rating;
   clearInterval( this.transInterval);
  console.log("New rating: ", rating);
}
  ngOnInit(): void {
    this._Activatedroute.params.subscribe(params => {
      this.roomId = params['roomId'];
    
      this.getcandidateToken();
    })
  }

  speakText(question): void {
    this.speechRecognitionService.stopRecording();  
   
    this.textToSpeechService.speak(question, 'en-IN', () => {
      
      this.transcript='';
      setTimeout(() => {
        this.speechRecognitionService.startRecording();
      }, 10);
    });
  }

  onEdit(i){
   this.userRecordingArray[i].edit = true;
   clearInterval( this.transInterval);
  }


  getcandidateToken() {
    this.isLoading = true;
    this.candidateService.getcandidateToken(this.roomId).subscribe(result => {

      if (result && result.Data != null) {
        this.candidateData = result.Data;
        this.questionList = this.candidateData?.questionList;
        this.totalQuestion = this.questionList.length;
        this.currentQuestion = 1;
        this.question = this.questionList.length > 0 ? this.questionList[0] : null;

        this.candidateDetails = this.candidateData?.candidateDetails;
        this.questionAnswers = this.question?.questionAnswers;
        this.isLoading = false;
        this.userRecordingArray.push({question:this.question.description,answer:'',edit:false});
        this.JoinRooms(this.candidateData.token);
      }
      else {
        this.isLoading = true;
        this.toastrService.error("there is some network issue please try after sometime");
      }
    });
  }
  timeLeft: number = 30;
  interval: any;

  getSecond(timeString) {
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseFloat(timeParts[2]);
    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    return totalSeconds;
  }
  formattedTime='';
  startTimer() {
    
    this.textToSpeechService.stop()
    this.speakText(this.question.description);
   
    this.timeLeft = this.getSecond(this.question.timeToDisplayInSecond);
    this.interval = setInterval(() => {
      if (this.timeLeft != 0) {
      let totalSeconds= this.timeLeft;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        this.formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        this.timeLeft--;
        //this.startTimer();
      }

      else if (this.timeLeft == 0) {
        if (this.currentQuestion != this.totalQuestion) {
          this.nextQuestion(this.question);
        } else if (this.currentQuestion == this.totalQuestion) {
          setTimeout(() => {
            this.isSubmit = true;
            clearInterval(this.interval);
            this.submit();
            return;
          }, 5000);
        }
      }
    }, 1000)

  }

  pauseTimer() {
    clearInterval(this.interval);
  }
  nextQuestion(question: any) {
    this.timeLeft = this.getSecond(this.question.timeToDisplayInSecond);
    this.isSubmit = false;
    this.submitAnswer();
    this.question = this.questionList[this.currentQuestion];
    this.questionAnswers = this.question?.questionAnswers;
    this.start = new Date(0, 0, 0);
    //this.start.setSeconds(t);
    this.userRecordingArray.push({question:this.question.description,answer:'' ,edit:false});
    this.answer = "";
    this.currentQuestion = this.currentQuestion + 1;
    if (this.currentQuestion == this.totalQuestion) {
      this.isSubmit = true;
      this.isAnsDoneForAllQuestion=true;

let lastQuestionMilisecond=this.timeLeft * 1000;
      setTimeout(() => {
        this.speechRecognitionService.stopRecording();  
        clearInterval(this.transInterval);
      },  lastQuestionMilisecond);
     
    }
    clearInterval(this.interval);
    this.startTimer();
  }
  // tabWasClosed=true;
  // doBeforeUnload() {
  //   if (document.visibilityState === 'hidden') {
  //     this.tabWasClosed = true;
  //   }

  //   return false;
  // }

  // doUnload() {
  //   if (this.tabWasClosed) {
  //   }
  // }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    this.submit();
    return false;
  }


  submitAnswer() {
    
    this.answer=this.userRecordingArray[this.currentQuestion-1].answer;
    this.answerModel = {
      jobId: this.candidateDetails.jobId??0,
      candidateId: this.candidateDetails.id??0,
      questionId: this.question?.id,
      answer: this.answer
    }

    this.candidateService.SaveCandidateAnswer(this.answerModel).subscribe({

      next: (res: any) => {
      }

    });
  }

  submit() {
    
    this.isLoading = true;
    this.submitAnswer();
    let model={candidateId:this.candidateDetails.id??0,jobId: this.candidateDetails.jobId??0,refNumber:this.roomId}
    this.candidateService.GenerateCandidateInterviewSummary(model).subscribe(x=>{
      window.location.href = location.origin+'/thankyou';
      this.toastrService.success("Thanks for record your interview, we will get back to you!");
      this.isLoading = false;
      //location.href = '/';
     });
  }

  //#region   Meeting section 

  meeting = new Meeting();
  localStream: any;
  token: any;
  ATList = [];
  username = '';
  candidateId = '';
  room: any;

  //todo
  audioMute() { 


  }
  videoMute() {


   }
  endCall() {
    var r = confirm("You want to quit?");
    if (r == true) {
      this.submit();
    }
  }

  JoinRooms(token: any) {

    let component = this;
    let mycom = component;
    let room = component.room;
    let localStream = EnxRtc.joinRoom(token, component.meeting.config, (success: any, error: any) => {

      if (error && error != null) {

      }

      if (success && success != null) {
        setTimeout(() => {
          this.startTimer();
        }, 1000);
        room = success.room;
        var ownId = success.publishId;
        mycom.setLiveStream(localStream);
        for (var i = 0; i < success.streams.length; i++) {
          this.room.subscribe(success.streams[i]);

        }

        // Active Talker list is updated
        room.addEventListener('active-talkers-updated', function (event: any) {

          mycom.ATList = event.message.activeList;
          // var video_player_len = document.querySelector('#multi_video_container_div').childNodes;
          var video_player_len = document.querySelector('#multi_video_container_div')?.childNodes;
          if (event.message && event.message !== null && event.message.activeList && event.message.activeList !== null) {

            if (mycom.ATList.length == video_player_len?.length) {
              return;
            }
            else {
              (<HTMLInputElement>document.querySelector('#multi_video_container_div')).innerHTML = "";
              for (var stream in room.remoteStreams.getAll()) {
                var st = room.remoteStreams.getAll()[stream];
                for (var j = 0; j < mycom.ATList.length; j++) {
                  if (mycom.ATList[j]['streamId'] == st.getID()) {
                    mycom.setLiveStream(st, mycom.ATList[j]['name']);
                  }
                }
              }
            }
          }

        });

        // Stream has been subscribed successfully
        room.addEventListener('stream-subscribed', function (streamEvent: any) {

          var stream = (streamEvent.data && streamEvent.data.stream) ? streamEvent.data.stream : streamEvent.stream;
          for (var k = 0; k < mycom.ATList.length; k++) {
            if (mycom.ATList[k]['streamId'] == stream.getID()) {
              component.setLiveStream(stream, mycom.ATList[k]['name']);
            }
          }
        });
        // Listening to room-record-on
        var timeStart = 0;
        let component = this;
        let mycomp = component;
        room.addEventListener("room-record-on", function (data: any) {

          // $('#progress').hide();
          component.meeting.$userRecordData.StartTime = new Date();

        });
        // Notification recording stopped to all
        room.addEventListener("room-record-off", function (event: any) {

          mycomp.meeting.$userRecordData.EndTime = new Date();
          // Recording stopped, Update UI
        });

        // Listening to Incoming Data
        room.addEventListener("active-talker-data-in", function (data: any) {
          var obj = {
            'msg': data.message.message,
            'timestamp': data.message.timestamp,
            'username': data.message.from
          };
          // Handle UI to display message
        });

        // Stream went out of Room
        room.addEventListener("stream-removed", function (event: any) {


        });
      }
    });
  }

  //$ code should be convert in to javascript or ts
  setLiveStream(stream: any, userName = '') {
    let component = this;
    let countStream = 0;
    // Listening to Text Data
    stream.addEventListener('stream-data', function (e: any) {
      var text = e.msg.textMessage;
      var html = $("#multi_text_container_div").html();
      $("#multi_text_container_div").html(html + text + "<br>");
    });

    if (!stream.local) {
      var newStreamDiv = document.createElement('div');
      newStreamDiv.setAttribute('id', 'liveStream_' + countStream);
      newStreamDiv.setAttribute('class', 'live_stream_div');
      (<HTMLInputElement>document.getElementById('multi_video_container_div')).appendChild(newStreamDiv);
      stream.show('liveStream_' + countStream, component.meeting.options);
      var node = document.createElement('div');
      if (userName !== "") {
        node.innerHTML = userName;
        node.classList.add("name-div");
        (<HTMLInputElement>document.getElementById('multi_video_container_div')).appendChild(node);
      }

      countStream++;
    }
    else {
      component.username = stream.getAttributes().name;
      component.meeting.options.player.loader.class = "";
      component.meeting.options.player.loader.show = false;
      stream.show('local_video_div', component.meeting.options);
      var node = document.createElement('div');
      node.innerHTML = component.username;
      node.classList.add("name-div");
      (<HTMLInputElement>document.getElementById('local_video_div')).appendChild(node);
    }
  }
  //#endregion


}
