import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent  {
  @Input() rating: number = 0; // Current rating value (can be passed from parent)
  @Input() starCount: number = 5; // Total number of stars
  @Input() readonly: boolean = false; // Make it read-only if true

  @Output() ratingUpdated = new EventEmitter<number>(); // Emit updated rating

  stars: boolean[] = [];

  ngOnInit() {
    this.stars = Array(this.starCount).fill(false);
  }

  updateRating(index: number) {
    if (this.readonly) {
      return; // Prevent updating if readonly
    }

    this.rating = index + 1;
    this.ratingUpdated.emit(this.rating); // Emit the updated rating
  }

  isFilled(index: number): boolean {
    return index < this.rating;
  }
}
