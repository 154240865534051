import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { MustMatch } from 'src/app/_helper/must-match.validator';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/_config/api.service';
import { APIConfig, AppRoles } from 'src/app/_config/api.config';
import { MatInput } from '@angular/material/input';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loginFormOtp!: FormGroup;
  logoUrl=environment.homelogoUrl;
  frmOtp!: FormGroup;
  loginPhone!: FormGroup;
  loading = false;
  hideControl=environment.hideControl;
  submitted = false;
  viaphonesubmitted = false;
  isSubmit = false;
  isOTPVerified=false;
  error = '';
  isLoggedIn = false;
  role?= true;
  user: any;
  phoneDiv: boolean = true;
  phoneDivOTP: boolean = true;
  @ViewChild('otp') otpElement: MatInput;
  
  forClient: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    autoplay:true,
    autoplayTimeout:5000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }


  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService,
    private route: ActivatedRoute,
    private authenticationService: UserService, private toastrService: ToastrService, private sessionService: LocalStorageService, private userService: UserService) {
    const userDetail = this.sessionService.retrieve('user');
   
    if(this.hideControl){
      this.router.navigate(['/login']);
      return;
    }
   
   
    if (userDetail != null && userDetail.Data != undefined) {
     
      this.router.navigate(['/home']);
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      otp:['',this.isOTPVerified?Validators.required:null],
      mode:['GetOTP']
    });

    this.loginPhone = this.formBuilder.group({
      // phoneNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
      phoneNo: ['', [Validators.required, Validators.email]]
    })
    this.loginFormOtp = this.formBuilder.group({
      emailOrMobileNo: [''],
      password: ['', Validators.required],
      otp: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword'),
    });
    this.frmOtp = new FormGroup({
      emailOrMobileNo: new FormControl(['', Validators.required]),
      otp: new FormControl(['', Validators.required])
    });

  }



  changeRole(e: any) {
    this.phoneDiv = true;
    this.phoneDivOTP = true;
    if (e.target.value == "loginemail") {
      this.role = true;

    }
    else {
      this.role = false;
    }
  }
  get f() { return this.loginForm.controls; }

  get ph() { return this.loginPhone.controls; }
  get photp() { return this.loginFormOtp.controls; }

  resendOTP(){
    this.loginForm.controls['otp'].setValue('');
    this.loginForm.controls['mode'].setValue('GetOTP');
    
    this.loginForm.controls['otp'].removeValidators([Validators.required]);
    this.loginForm.get('otp').updateValueAndValidity();
   
      if (this.loginForm.invalid) {
        return;
      }
      this.authenticationService.login(this.loginForm.value).subscribe((x=>{
        if (x.StatusCode==200 && x.Data == null) {
          this.loginForm.controls['mode'].setValue('VerifyOTP');
          this.toastrService.success(x.Message, '', {
            timeOut: 4000,
          });
          this.loginForm.controls['otp'].setValidators([Validators.required]);
          this.loginForm.get('otp').updateValueAndValidity();
          this.isOTPVerified=true;
          this.isSubmit = false;
         
        }else{
          this.toastrService.error(x.Message, '', {
            timeOut: 4000,
          });
        }
  
      }))
  
    
  }
  onSubmit() {
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = false;
    this.isSubmit = true;
    this.authenticationService.login(this.loginForm.value)

      .subscribe({

        next: (res: any) => {
          this.isSubmit=false;
          if(res.StatusCode!=200){
            this.toastrService.error(res.Message, '', {
              timeOut: 4000,
            });
            return;
          }
          //otp sent on given email id
          if (res.StatusCode==200 && res.Data == null) {
            this.toastrService.success(res.Message, '', {
              timeOut: 4000,
            });
            this.loginForm.controls['otp'].setValidators([Validators.required]);
            this.loginForm.controls['mode'].setValue('VerifyOTP');
            this.loginForm.get('otp').updateValueAndValidity();
            this.isOTPVerified=true;
            this.isSubmit = false;
          }
          else {
            if(!this.loginForm.value.otp){
             return;
            }
            this.userService.getUserDetails()
              .subscribe({
                next: (res: any) => {
                  
                  if (res.Data != null) {
                    const userDetail = this.sessionService.retrieve('userDetail');
                    if (userDetail != null) {
                      const role = userDetail.Data?.roles;
                      let currentRole = role[0].code.toLowerCase();
                      let rountName=AppRoles.filter(x=>x.code.toLowerCase()==currentRole.toLowerCase())[0]?.route;
                      let route = '/' + rountName + '/dashboard';
                      if(currentRole=='superadmin'){
                        this.router.navigate(['/superadmin']);
                        return;
                      }
                      this.router.navigate([route]);
                    }
                    else {
                      this.router.navigate(['/login']);
                    }

                  }
                }
              }
              );
          }

        },
        error: error => {
          this.toastrService.error("user name and password is not correct");
          this.isSubmit = false;
        }


      });


  }


  loginViaPhone() {
    this.viaphonesubmitted = true;
    if (this.loginPhone.invalid) {
      return;
    }
    this.viaphonesubmitted = false;
    var phoneNo = this.loginPhone.controls['phoneNo'].value;
    this.frmOtp.patchValue({ emailOrMobileNo: phoneNo, otp: '' });
    this.authenticationService.loginViaPhone(phoneNo).subscribe(result => {
      if (result) {
        if (result.StatusCode != 200) {
          this.toastrService.error(result.Message, '', {
            timeOut: 1000,
          });
        }
        else {
          this.toastrService.success(result.Message);
          this.phoneDivOTP = false;
        }
      }
    })
  }
  frmSubmit() {

    this.submitted = true;
    if (this.frmOtp.invalid) {
      return;
    }
    this.apiService._post(APIConfig.loginViaOTP, this.frmOtp.value).subscribe({
      next: (d: any) => {
        // if(res.StatusCode==200){
        if (d.StatusCode != 200) {
          this.toastrService.error(d.Message, '', {
            timeOut: 1000,
          });
          this.isSubmit = false;
        } else {

          this.sessionService.store('user', d);
          //this.sessionService.observe(res);  
          // setTimeout(() => {
          //   this.sessionService.clear();
          // }, 3600000);


          this.userService.getUserDetails()
            .subscribe({
              next: (res: any) => {
                if (res.Data != null) {
                  // this.toastrService.success("Login Successfully");
                  const userDetail = this.sessionService.retrieve('userDetail');
                  if (userDetail != null) {
                    const role = userDetail.Data?.roles;
                    let currentRole = role[0].code.toLowerCase();
                    let route = '/' + currentRole + '/dashboard';
                    this.router.navigate([route]);
                  }
                  else {
                    this.router.navigate(['/login']);
                  }
                }
              }
            });
        }
        // }else{
        //   this.toastrService.error(res.Message);
        // }
      },
      error: error => {
        this.toastrService.error("user name and password is not correct");
        this.isSubmit = false;
      }
    });

  }
  onotpSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = false;
    var email = this.loginPhone.controls['phoneNo'].value;
    this.user = this.loginFormOtp.value;
    this.user.emailOrMobileNo = email;
    this.authenticationService.forgetPassword(this.user)

      .subscribe({
        next: (res: any) => {
          if (res.StatusCode == 200) {
            this.toastrService.success(res.Message);
            this.router.navigate(['/home']);
          }
          else {
            this.toastrService.error(res.Message);
            this.router.navigate(['/home']);
          }

        },
        error: error => {
          this.toastrService.error("user name and password is not correct");

        }


      });


  }
  validateNo(e: any): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }
}
