<!-- <div
  (window:beforeunload)="doBeforeUnload()"
  (window:unload)="doUnload()"> -->

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="logo"><img src="../../../../assets/img/tol-logo.svg" height="100" class="img-fluid" /></div>
      </div>
    </div>
  </div>

<section class="recording-wrapper">
  <div *ngIf="!isLoading" class="row">
    <div class="col-md-12">
      <div class="row">
        
        <div class="col-md-8">
            <div class="candidate-name mb-2"><strong>{{candidateDetails?.fullName}}</strong><span
                class="candidate-post ml-2">({{candidateDetails?.designation}})</span></div>
            <!-- <div class="qdegi mb-2"><strong>Designation:</strong> {{candidateDetails?.designation}}</div> -->
            <div class="technology mb-2"><strong>Technology:</strong> <span class="skill">{{candidateDetails?.technology}}</span></div>
        
            <div class="video_container_div mt-3">
            <!--start row-->
            <div class="col-12 p-0" style="position: relative;">
              <div id="local_video_div" class="adjustvideo">

              </div>
              <!--add button-->
              <div class="controls" id="controls">
                <div>
                  <!-- <img src="../../../../assets/enablex/mike.png" style="margin-right: 20px;cursor: pointer;"
                    class="cus_img_icon icon-confo-mute" (click)="audioMute()" title="mute audio" />
                  <img src="../../../../assets/enablex/video.png" style="margin-right: 20px;cursor: pointer;"
                    class="cus_img_icon icon-confo-video-mute" title="mute video" (click)="videoMute()" /> -->
                  <img src="../../../../assets/enablex/end-call.png" style="margin: 0 5px; cursor: pointer;"
                    class="cus_img_icon end_call" title="End_call" (click)="endCall()" />
                </div>
              </div>
              <!--close add button-->
            </div>
            <!--close row-->
            <!--start row-->

            <!--close row-->
          </div>

          <!-- multi Video section -->
          <!-- <div class="row">
            <div class="col-md-12">
              <div id="multi_video_container_div">
    
              </div>
            </div>
          </div> -->

        </div>

        <div class="col-md-4">
          <div class="question-sec">
            <div class="timer-wrapper">
            <div class="timer">{{formattedTime}} Seconds Left....</div>
            <button mat-button *ngIf="!isSubmit" (click)="nextQuestion(question)"
                class="btn btn-primary">Next..</button>
              </div>
              <div class="ques-ans-wrapper">
                <ng-container *ngFor="let item of userRecordingArray;let i =index;">
                  <div class="questions-col mb-3"><strong>Q:{{i+1}} {{item.question}}</strong></div>
                  <div class="ans-box" *ngIf="!isAnsDoneForAllQuestion && item.edit==false && item.answer!=''"> Ans : {{item.answer}}</div>
                  <div class="ans-box" *ngIf="isAnsDoneForAllQuestion  && item.edit==false"> <i  class="fa fa-pencil-square-o" aria-hidden="true" (click)="onEdit(i)"></i> : {{item.answer}}</div>
                  <input type="text"  class="ans-box" *ngIf="item.edit==true" [(ngModel)]="item.answer" value="{{item.answer}}" />
                </ng-container>
           
          </div>
            <div class="answer-col mb-2">
              <div *ngIf="question.questionType=='O'">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="answer">
                  <mat-radio-button value="{{questionAnswers?.answerA}}">{{questionAnswers?.answerA}}</mat-radio-button>
                  <mat-radio-button value="{{questionAnswers?.answerB}}">{{questionAnswers?.answerB}}</mat-radio-button>
                  <mat-radio-button value="{{questionAnswers?.answerC}}">{{questionAnswers?.answerC}}</mat-radio-button>
                  <mat-radio-button value="{{questionAnswers?.answerD}}">{{questionAnswers?.answerD}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <!--<div class="form-group" *ngIf="question.questionType!='O'">
                 <mat-form-field class="field-round">
                  <mat-label>Answer</mat-label>
                 <textarea matInput placeholder="answer..." [(ngModel)]="answer"></textarea>
                </mat-form-field> 
              </div>-->
            </div>
            <ng-container *ngIf="this.currentQuestion == this.totalQuestion">
              <p style="font-size: 15px;">Thanks for using our AI interview platform! Your feedback helps us improve.</p>
              <app-star-rating 
    [rating]="0"
    [starCount]="5"
    (ratingUpdated)="onRatingUpdated($event)"
  ></app-star-rating>
            </ng-container>


          <p *ngIf="isSubmit"> <b> Note: </b> If you want to edit your answer, click on the edit icon before your answer, and then click the 'End Call' button below.</p> 
            <div class="cta mt-4" style="text-align: center;">
              <button mat-button *ngIf="isSubmit && feedbackRating>0" class="btn btn-primary" (click)="submit()">End Call</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
     
</section>
<!-- </div> -->