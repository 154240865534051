import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { APIConfig, AppRoles } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_config/api.service';
import { User } from 'src/app/_models/user';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.css']
})
export class SSOLoginComponent implements OnInit {

  currentUserSubject = new BehaviorSubject<any>(new User());;
  public currentUser: Observable<User>;
  constructor(private router: Router,
    private sessionService: LocalStorageService,
    private _activatedroute: ActivatedRoute,
    private _apiService: ApiService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let sessionId=this._activatedroute.snapshot.params['sessionId'];
    let redirectUrl= this._activatedroute.snapshot.params['redirectUrl'];
      let route='/'+ (redirectUrl==undefined?'':redirectUrl);
      this._apiService._get(APIConfig.SSOLogin, { sessionId: sessionId }).subscribe((res: any) => {
        if (res.StatusCode == 200) {
          this.sessionService.store('user', res);
          this.userService.getUserDetails().subscribe((res: any) => {
            if (res.Data != null) {
              const userDetail = this.sessionService.retrieve('userDetail');
              if (userDetail != null) {
                const role = userDetail.Data?.roles;
                let currentRole = role[0].code.toLowerCase();
                let rountName = AppRoles.filter(x => x.code.toLowerCase() == currentRole.toLowerCase())[0]?.route;
               if(rountName=='superadmin'){
                this.router.navigate(['/superadmin']);
                return;
               }
                if(route=='/'){
                   route = '/' + rountName + '/dashboard';
                }
                this.router.navigate([route]);
              }
              else {
                this.router.navigate(['/login']);
              }
            }
          });
        }
      });
  }

}
