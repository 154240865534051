export const APIConfig={
      navitaion:"navigationmaster",
      userNotification:"user/getUserNotification",
      readNotification:"user/readNotification",
      readAllNotification:"user/readAllNotification",
      getPartnerCandidateSummary:"candidate/getCandidateSummary",
      downloadCandidateSummary:"candidate/downloadCandidateSummary",
      loginViaOTP: "Account/AuthenticateViaOtp",
      addRecruiter: "Account/AddRecuriter",
      getRecruiter: "Account/GetPartnerRecuriter",
      partnerRecuriterActive: "Account/PartnerRecuriterActive",
      logout:'account/logout',
      GetLoggedInUserDetails:'account/GetLoggedInUserDetails',
      getClientApplicantSummary:"job/GetClientApplicantSummary",
      getAllNavigationRole:"NavigationMaster/getAllNavigationRole",
      navigationRole: 'navigationMaster/navigationRole',
      getDocument: 'user/document',
      uploadDocument: 'user/uploadUserDocument',
      getAllRole:'Role/getAll',
      role:"role",
      reports:'Report',
      allMasterType:'Master/MasterType',
      getMasterById:'Master/MasterList',
      GetAuditLogDetail:'admin/GetAuditLogDetail',
      GetTenantByHostName:'admin/GetTenantByHostName',
      SaveUpdateTenant:'admin/SaveUpdateTenant',
      GetAllTenant:'admin/GetAllTenant',
      UpdateCTC:'candidate/UpdateCTC',
      GetCandidateInterviewSummary:'candidate/GetCandidateInterviewSummary',
      MatchCandidate:'job/MatchCandidate',
      MatchMoreCV:'job/MatchMoreCV',
      ActivateUser:'User/ActivateUser',
      dashboard:'admin/dashboard',
      VerifyUserEmail:'User/verifyUserEmail',
      SSOLogin:'Account/SSOLogin',
      whatAppEnableDisabled:'user/WhatAppEnableDisabled',
      updateParnerCategoryOrVette:'user/UpdateParnerCategoryOrVette',
      ApplyMatchedCandidate:"candidate/applyMatchedCandidate",
      checkedForAvailability:"job/checkedForAvailability",
      isAlreadyApplied:"job/isAlreadyApplied",
      getPartnerId:"home/getPartnerId",
      saveCandidateAndApply:"home/saveCandidateAndApply",
      applyMatchedCandidates:"candidate/applyMatchedCandidates"
}



export const  AppRoles=[
      {code:'admin',route:'admin'},
      {code:'partner',route:'partner'},
      {code:'client',route:'client'},
      {code:'executive',route:'executive'},
      {code:'contractualAdmin',route:'admin'},   
      {code:'permanentAdmin',route:'admin'},  
      {code:'salesExecutive',route:'admin'},  
      {code:'External',route:'admin'},  
      {code:'Finance',route:'admin'},  
      {code:'Internal',route:'admin'},
      {code:'partnerExecutive',route:'admin'},
      {code:'superadmin',route:'superadmin'},
      
    ]
