    <section class="banner-section">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h2>
                        Accelerate hiring by 5X to 10X using <br /> <span class="gradient-txt">DigiRecruitNet</span>
                    </h2>
                    <h4>DigiRecruitNet removes the toil from technical hiring</h4>
                </div>
            </div>
        </div>
    </section>

    <section class="feature-section">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <svg class="w-16 h-16 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="robot"
                                    class="svg-inline--fa fa-robot fa-2xs text-purple-600" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <path fill="currentColor"
                                        d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z">
                                    </path>
                                </svg>
                            </svg>
                        </div>
                        <div class="feature-title">Accelerate your technial hiring by 5X to 10X</div>
                        <div class="content">
                            Job post to offer in 1 week. Get screening & L1 interviews done by AI on day 1 of job
                            application.
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <svg class="w-16 h-16 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><svg
                                    aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bolt"
                                    class="svg-inline--fa fa-bolt fa-2xs text-purple-600" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor"
                                        d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z">
                                    </path>
                                </svg></svg>
                        </div>
                        <div class="feature-title">Automate interviews and save time & money</div>
                        <div class="content">
                            DigiRecruitNet pays for itself many times over. Save 90% in HR & engineering time and cost
                            per
                            position.
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <svg class="w-16 h-16 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><svg
                                    aria-hidden="true" focusable="false" data-prefix="fas" data-icon="list-check"
                                    class="svg-inline--fa fa-list-check fa-2xs text-purple-600" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z">
                                    </path>
                                </svg></svg>
                        </div>
                        <div class="feature-title">Reclaim HR & engineering bandwidth</div>
                        <div class="content">
                            Engineers can now focus on building product and spend 80% lesser time interviewing
                            candidates.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-4">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <svg class="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><svg
                                    aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clock"
                                    class="svg-inline--fa fa-clock fa-2xs text-purple-600" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                    </path>
                                </svg></svg>
                        </div>
                        <div class="feature-title">Real-time evaluation</div>
                        <div class="content">
                            Receive instant feedback on candidate's problem solving and coding skills.
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <svg class="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><svg
                                    aria-hidden="true" focusable="false" data-prefix="fas" data-icon="droplet"
                                    class="svg-inline--fa fa-droplet fa-2xs text-purple-600" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path fill="currentColor"
                                        d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7C172.6 4.2 181.5 0 191.1 0h1.8c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z">
                                    </path>
                                </svg></svg>
                        </div>
                        <div class="feature-title">Reduce drop-offs by 5X to 10X</div>
                        <div class="content">
                            No more postponing of technical interviews because of interviwer unavailability.
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <svg class="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><svg
                                    aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star"
                                    class="svg-inline--fa fa-star fa-2xs text-purple-600" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor"
                                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z">
                                    </path>
                                </svg></svg>
                        </div>
                        <div class="feature-title">5X to 10X hiring experience</div>
                        <div class="content">
                            Give your candidates an amazing hiring experience that is smooth, free of anxiety and devoid
                            of human judgement.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="partner-section">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h3> Are you hire <span class="gradient-txt">Candidates?</span></h3>
                    <button class="btn btn-primary btn-sm">PARTNER WITH US!</button>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="comment-box">
                        <div class="user-img">
                            <img src="../../../../assets/img/pic1.jpg" alt="partner" />
                            <svg class="fill-current"
                            viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z">
                            </path>
                        </svg>
                        </div>
                        <div class="comment">— DigiRecruitNet filtered our pool of candidates from 200 to 4 and helped
                            me
                            hire 5x to 10x faster!</div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="comment-box">
                        <div class="user-img">
                            <img src="../../../../assets/img/pic1.jpg" alt="partner" />
                            <svg class="fill-current"
                                viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z">
                                </path>
                            </svg>
                        </div>
                        <div class="comment">— Our candidate drop offs are almost non-existent thanks to DigiRecruitNet super fast interview process. We can rely on them.
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="comment-box">
                        <div class="user-img">
                            <img src="../../../../assets/img/pic1.jpg" alt="partner" />
                            <svg class="fill-current"
                            viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z">
                            </path>
                        </svg>
                        </div>
                        <div class="comment">— DigiRecruitNet has saved us 100s of hours of engineering time. Our engineers can now focus on what they do best - building products.
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="want-hire-wrapper">
                    <div class="want-hire">
                        <div class="title"><span class="gradient-txt">Want to hire</span> 5X to 10X faster?</div>
                        Lets connect and discuss how we can be of help
                    </div>
                    <div class="email-box">
                        <input type="text" placeholder="Company Email"/>
                        <button class="btn btn-primary">Submit</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>

<div *ngIf="false">
    <section class="service-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="ready-to-see-heading">Ready to see what <span class="dig-recruit-txt">DigiRecruitNet</span> can do for you?
                    </h3>
                </div>
                <div class="col-md-4">
                    <div class="service-item">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/digi/objective.jpg" height="100" />
                            <span class="counter ms-auto">01</span>
                        </div>
                        <p>
                            Objectively assess candidates and employees on any skill with our 3000+ skills library
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="service-item">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/digi/hire.jpg" height="100" />
                            <span class="counter ms-auto">02</span>
                        </div>
                        <p>
                            Hire the best candidates with pre-employment tests calibrated to your organization
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="service-item">
                        <div class="d-flex align-items-center">
                            <img src="assets/img/digi/inteview.jpg" height="100" />
                            <span class="counter ms-auto">03</span>
                        </div>
                        <p>
                            Conduct virtual interviews effortlessly with our Live Interview platform and coding
                            assessment platform
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section>
        <div class="container">
            <div class="row text-center">
                <div class="col-md-4">
                    <div class="circle-wrap">
                        <div class="circle">
                            <div class="mask full-1">
                                <div class="fill-1"></div>
                            </div>
                            <div class="mask half">
                                <div class="fill-1"></div>
                            </div>
                            <div class="inside-circle"> 40% </div>
                        </div>
                    </div>
                    <p class="progress-content">Reduction in hiring cost</p>
                </div>
                <div class="col-md-4">
                    <div class="circle-wrap">
                        <div class="circle">
                            <div class="mask full-2">
                                <div class="fill-2"></div>
                            </div>
                            <div class="mask half">
                                <div class="fill-2"></div>
                            </div>
                            <div class="inside-circle"> 65% </div>
                        </div>
                    </div>
                    <p class="progress-content">Reduction in time-to-hire</p>
                </div>
                <div class="col-md-4">
                    <div class="circle-wrap">
                        <div class="circle">
                            <div class="mask full-3">
                                <div class="fill-3"></div>
                            </div>
                            <div class="mask half">
                                <div class="fill-3"></div>
                            </div>
                            <div class="inside-circle"> 75% </div>
                        </div>
                    </div>
                    <p class="progress-content">Increase in interview-to-selection ratio</p>

                </div>
            </div>
        </div>
    </section>
    <section class="brand-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>
                        Trusted by 500+ organisations worldwide
                    </h4>
                </div>
                <div class="col-12">
                    <div class="brands">
                        <div class="brand-logo">
                            <img src="assets/img/digi/financial-express.png" />
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/techgig.png" />
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/business-world.png" />
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/indian-express.png" />
                        </div>
                        <div class="brand-logo">
                            <img src="assets/img/digi/express-computer.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>