<!--footer start-->
<footer class="footer" *ngIf="router.url !== '/login'&& router.url !== '/register'&& router.url !== '/recording'&& router.url !== '/forgetPassword'">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <div class="footer-widget">
          <!-- <div class="footer-logo"><img style="width: 200px;;" src="assets/img/tol-footer-logo.png" alt="Footer Logo"></div> -->
          <div class="footer-content">
            <p>
             TalentOnLease is aimed at addressing the problem (shortage of quality tech talent) faced by the industry by bringing together Clients and Partners on a single platform to collaborate for mutual gains.  
            </p>
          </div>
          <div class="social-links">
            <ul class="d-flex align-items-center">
              <li><a href="https://www.facebook.com/Talent-on-Lease-2181624008775260" target="_blank"><i class="fa fa-facebook"></i></a></li>
              <!-- <li><a href="https://www.instagram.com/talentonlease/" target="_blank"><i class="fa fa-instagram"></i></a></li> -->
              <li><a href="https://www.linkedin.com/company/talentonlease/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
              <!-- <li><a href="#" target="_blank"><i class="fa fa-twitter"></i></a></li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-4">
                <div class="footer-widget">
                  <div class="footer-title">Quick Links</div>
                  <ul>
                    <li><a routerLink="/find-candidates" (click)="scrollToTop()">Find Candidates</a></li>
                    <li><a routerLink="/find-jobs" (click)="scrollToTop()">Find Jobs</a></li>
                    <li><a  href="https://talentonlease.com/blogs">Blogs</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="footer-widget">
                  <div class="footer-title">Hire</div>
                  <ul>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-full-stack-developer"> Full Stack Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-java-developer"> Java Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-dot-net-developer"> Dot NET Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-mean-stack-developer"> Mean Stack Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-mern-stack-developer"> Mern Stack Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-react-js-developer"> ReactJS Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-angular-developer"> Angular Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-mobile-app-developer"> Mobile App Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-python-developer"> Python App Developer</a>
                    </li>
                    <li>
                      <a (click)="scrollToTop()" routerLink="/hire-software-developer"> Software Developer</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="footer-widget">
                  <div class="footer-title">Others</div>
                  <ul>
                    <li><a routerLink="/about" (click)="scrollToTop()">About Us</a></li>
                    <li><a routerLink="/contact" (click)="scrollToTop()">Contact Us</a></li>
                  </ul>
                  <br>
                  <ul>

                    <li><a routerLink="/privacy" (click)="scrollToTop()">Privacy Policy</a></li>
                    <li><a routerLink="/termcondition" (click)="scrollToTop()">Term & Condition</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" style="display: none;">
            <div class="footer-widget">
              <h5 class="footer-title">Do You Have any Questions?</h5>
              <form [formGroup]="sendQuery" (ngSubmit)="submitMessage()">
                <mat-form-field class="field-round mb-4">
                  <textarea matInput  formControlName="body"  [ngClass]="{ 'is-invalid': submitted && f['body'].errors }"   placeholder="Write your message/query here..."></textarea>
                 
                </mat-form-field>
                <div *ngIf="submitted && f['body'].errors" class="invalid-feedback">
              
                  <div *ngIf="f['body'].errors">message is required</div>
              </div>
                <button  type="submit" class="btn btn-primary pr65">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyright-sec">
          Copyright TalentOnLease © 2023. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer end-->