import { Component, OnInit } from '@angular/core';
import { Search } from 'src/app/_models/candidates';
import { HomeService } from 'src/app/_services/home.service';
import { Router } from '@angular/router';
import { JobsAvailableActionComponent } from '../../partner/jobs-available/jobs-available-action.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-webstorage';
import { RenderService } from 'src/app/_services/render.service';
import { Meta } from '@angular/platform-browser';
import { ApplyJobDialogComponent } from './apply-job-dialog/apply-job-dialog.component';
@Component({
  selector: 'app-find-apply-job',
  templateUrl: './find-apply-job.component.html',
  styleUrls: ['./find-apply-job.component.css']
})
export class FindApplyJobComponent implements OnInit {
  jobs: any;
  skills:any;
  experimence:any;
  search = new Search();
  jobdeailShow=false;
  JobDetails:any;
  totaljobs: any;
  selected: any;
  userId: any=null;
  isLoading= false;
  metaName='Find latest Full Stack, Java, App, Python Developer Jobs'
  constructor(private homeService: HomeService,private meta:Meta, 
    public dialog: MatDialog,private sessionService:LocalStorageService, private router: Router,private renderSvc: RenderService) {
  
      this.search =  this.sessionService.retrieve('jobFilter');
    
      this.getallJobs(this.search);
      }

  ngOnInit(): void {
    this.renderSvc.addLinkTag('https://talentonlease.com/find-jobs');
    this.meta.addTag({name:'description',content: 'Unlock your IT career at TalentOnLease! Find job opportunities that fit like a glove. Your dream IT job is just a click away. Explore now!'});
    this.meta.addTag({name:"keywords",content: 'developers job'});
  }
  ngOnDestroy(): void {
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
  }
  receivedJobHandler(p:any) {
    this.search = p;
    this.getallJobs(this.search);
  }
  getallJobs(model:any){   
    this.isLoading= true;
    this.homeService.getJobs(model).subscribe(result => {
      this.jobs=null;
      this.JobDetails=null ;
     this.isLoading= false;
      if(result && result.data!=null && result.data.length>0){
        this.jobs = result.data; 
        this.JobDetails=result.data[0]; 
        this.jobdeailShow=true;
        this.totaljobs=result.totalCount;
      }
      else{
        this.jobdeailShow=false;
        this.totaljobs=0;
      }
      
    })}
   
    getJobDetails(model:any){
    this.JobDetails=model;
    this.jobdeailShow=true;
    }
  
    applyJob(job:any){
      const userDetail =  this.sessionService.retrieve('userDetail');
        this.openDialogApply(job);
      
    }

    openDialogApply(job) {
     
      const dialogRef= this.dialog.open(ApplyJobDialogComponent,{
        width: '319px',
        height: '450px', 
         disableClose: true,
         data:job
       });
      }
}
