<div class="admin-logo">
  <a routerLink="/"><img class="tollogo" [src]="siteLogo" alt="Logo"></a>
  <button class="admin-toggler navbar-toggler" type="button" [class.active]="isActive" (click)="toggleClasses('')" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <span class="search-ico"><img src="assets/img/icon-search.svg" alt=""></span>
</div>
<div class="leftnavbar" [class.open]="isOpen">
  <div class="leftusr desk-nav">
    <a class="user-sec" routerLink="/my-profile" (click)="toggleClasses('my-profile')">
      <span class="user-pic"><img src="assets/img/pic1.jpg" alt="User"></span>
      <span class="user-cont">View Profile</span>
    </a>
  </div>
  
  <div class="navbar-collapse cus-scr">
      <ul class="navbar-nav">
        <li class="nav-item" *ngFor="let menu of adminmenu" (click)="toggleDropdown(menu.id)"  [class.open]="dropdownStates[menu.id]">
          <a class="nav-link dropdown" *ngIf="menu.submenus.length>0" ><i class="ico ico-{{menu.code}}"></i>{{menu.description}}</a>
          <a class="nav-link" *ngIf="menu.submenus.length==0"  (click)="toggleClasses(menu)"  [ngClass]="{'active': menu.id === sideId}"><i class="ico ico-{{menu.code}}"></i>{{menu.description}}</a>
          <ul class="dropdown-menu" *ngIf="menu.submenus.length>0">
            <li *ngFor="let submenu of menu.submenus" [class.open]="dropdownStates[submenu.id]" >
              <a class="nav-link"   [ngClass]="{'active': submenu.id === sideId}"  (click)="toggleClasses(submenu)">{{submenu.description}}</a>
            </li> 
          </ul>
        </li>
      
        <li class="nav-item">
          <a class="nav-link" (click)="logOut()"><i class="ico ico-logout"></i> Log Out</a>
        </li>
      </ul>
    </div>
</div>
