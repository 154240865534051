import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { APIConfig } from 'src/app/_config/api.config';
import { Observable, forkJoin, map } from "rxjs";
@Injectable({ providedIn: 'root' })
export class CandidateService {
    private readonly candidateEndPoint = 'candidate/';
    constructor(private http: HttpClient) {
      
      }
        getApplicantSummary() {
        return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}getApplicantSummary`)};

        getAll(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}getAll`,httpOptions)
        }

        getAppliedCandidate(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetAppliedCandidate`,httpOptions)
        }

        getOfferedCandidate(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetOfferedCandidate`,httpOptions)
        }

        GetCheckAvailibilityOfCandidate(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetCheckAvailibilityOfCandidate`,httpOptions)
        }
        getAvailableCandidate(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetAvailableCandidate`,httpOptions)
        }
        getHiredCandidate(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetHiredCandidate`,httpOptions)
        }

        getAllApplicant(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}getAllApplicant`,httpOptions)
        }
        

        getApplicantHistory(candidate:any) {
          const httpOptions = { params: candidate }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}history/${candidate.id}`,httpOptions)
        }
        getAppliedJobDetails(candidate:any) {
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetAppliedJobDetails?candidateId=${candidate}`)
        }
        getSelfRating(candidate:any) {
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetSelfRating?candidateId=${candidate}`)
        }
        getcandidate(candidate:any) {
          return this.http.get<any>(`${environment.apiUrl}candidate?candidateId=${candidate}`)
        }
        getapplicantsummary(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}getapplicantsummary`,httpOptions)
        }
        inviteInterview(candidate:any, job:any ){
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}inviteForRecordInterview/${candidate},${job}`)
        }
        copyInterviewLink(candidate:any, job:any ){
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}copyInterviewLink/${candidate},${job}`)
        }
        updateCandidateStatus(model:any){
          return this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}UpdateCandidateStatus`, model)
          .pipe(map(res => {         
            return res;
          }));
        }
        deleteCandidate(id:any){
          return this.http.delete<any>(`${environment.apiUrl}${this.candidateEndPoint}?Id=${id}`)
         }
        saveSelfRating(model:any){
          return this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}SaveSelfRating`, model)
          .pipe(map(res => {         
            return res;
          }));
        }
       
        applyJob(model:any){
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}apply`, httpOptions);
        
        }
        downloadCandidate(model:any){
         return this.http.get(`${environment.apiUrl}${this.candidateEndPoint}downloadCandidate`,{
            responseType: 'arraybuffer',params:model} 
           )
  
        }

        uploadResume(model:any, candidate:any){ 
        return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}uploadResume?candidateId=${candidate}`,model)
          .pipe(map(res => {  
                   
            return res;
          }));
        }

        getcandidateToken(roomId:any){
          return  this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetToken?roomid=${roomId}`)
          .pipe(map(res => {  
                   
            return res;
          }));
        }
        

        SaveCandidateAnswer(model:any){
          return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}SaveCandidateAnswer`,model)
          .pipe(map(res => {                   
            return res;
          }));
        }

        GenerateCandidateInterviewSummary(model){
          const httpOptions = { params: model }; 
          return  this.http.get(`${environment.apiUrl}${this.candidateEndPoint}GenerateCandidateInterviewSummary`,httpOptions);
        }
        
        getCandidateGivenAnswer(model:any) {
          const httpOptions = { params: model }; 
          return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetCandidateGivenAnswer`,httpOptions)
        }


        uploadBGVDocument(model:any, candidate:any){ 
          return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}uploadBGVDocument?candidateId=${candidate}`,model)
            .pipe(map(res => {  
                     
              return res;
            }));
          }

          uploadNDADocument(model:any, candidate:any){ 
            return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}uploadNDADocument?candidateId=${candidate}`,model)
              .pipe(map(res => {  
                       
                return res;
              }));
            }


          uploadAssesment(model:any, candidate:any){ 
            return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}UploadAssesment?candidateId=${candidate}`,model)
              .pipe(map(res => {  
                       
                return res;
              }));
            }
        downloadApplicant(model:any){ 
          return this.http.get(`${environment.apiUrl}${this.candidateEndPoint}downloadApplicant`,{
             responseType: 'arraybuffer',params:model} 
            );
         }

         downloadCandidateSummary(model:any){ 
        return  this.http.get(environment.apiUrl + APIConfig.downloadCandidateSummary,{
            responseType: 'arraybuffer',params:model} 
           )
        } 

         createCandidate(model:any){
          return this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}`, model)
          .pipe(map(res => {         
            return res;
          }));
        }

        editCandidate(model:any){
          return this.http.put<any>(`${environment.apiUrl}${this.candidateEndPoint}`, model)
          .pipe(map(res => {         
            return res;
          }));
        }
         downloadJob(model:any){ 
         return this.http.get(`${environment.apiUrl}${this.candidateEndPoint}downloadJob`,{
            responseType: 'arraybuffer',params:model} 
           )
        }
        
        downloadCandidateFileFormate(): Observable<any[]>{
          let response1 = this.http.get(`${environment.apiUrl}${this.candidateEndPoint}downloadCandidateFileFormatExcel`,{
            responseType: 'blob'} 
           )
          let response2 = this.http.get(`${environment.apiUrl}${this.candidateEndPoint}downloadCandidateFileFormateDoc`,{
            responseType: 'blob'} 
           )
           return forkJoin(response1,response2);
        }
        bulkUploadFiles(files:any){
          return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}BulkUploadCandidate`,files)
          .pipe(map(res => {  
                   
            return res;
          }));
        }
        bulkUploadCVFiles(files:any,partnerId,technologyId){
          const headers = {
            'partnerId': partnerId, // Customize the header name as needed
            'technologyId': technologyId
          };
        
          return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}BulkUploadCandidateFromResume`,files,{headers})
          .pipe(map(res => {  
                   
            return res;
          }));
        }
        bulkCVFile(files:any){
          return  this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}uploadCV`,files)
          .pipe(map(res => {  
                   
            return res;
          }));
        }

      //   downLoadFile(data: any, type: string,fileName:string) {
      //     let blob = new Blob([data], { type: type});
      //     let url = window.URL.createObjectURL(blob);         
      //     let downloadLink = document.createElement('a');
      //     downloadLink.href =url;
      //     downloadLink.setAttribute('download', fileName);
      //     document.body.appendChild(downloadLink);
      //     downloadLink.click();
         
      // }
      getCandidateScore(model:any){
        const httpOptions = { params: model }; 

        return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetCandidateAssesmentScore`,httpOptions)
      }
      getCandidateFilterScore(filterData:any){
        return this.http.post<any>(`${environment.apiUrl}${this.candidateEndPoint}GetFilterAssesmentScore`,filterData)
      }
      getCandidateSkill(){
        return this.http.get<any>(`${environment.apiUrl}${this.candidateEndPoint}GetAssesmentScoreSkill`)
      }
  }
