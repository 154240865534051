<div class="star-rating">
    <span *ngFor="let star of stars; let i = index" (click)="updateRating(i)">

        <!-- <i class="star" style="color: green;" [ngClass]="{'filled': !isFilled(i)}" >&#9733;</i>  -->
        <i class="star"  [ngClass]="{'filled': isFilled(i)}">{{isFilled(i)?'&#9733;': '&#9734;'}}  </i> <!-- Empty green star -->


      <!-- <i class="star" [ngClass]="{'filled': isFilled(i)}">&#9733;</i> 
     
      <i class="star" [ngClass]="{'filled': isFilled(i)}">&#9734;</i>  -->
    </span>
  </div>