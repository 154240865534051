<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="logo"><img src="../../../../assets/img/tol-logo.svg" height="100" class="img-fluid" /></div>
    </div>
  </div>
</div>
<section class="instructions-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="instructions-img">
          <img src="../../../../assets/img/online-interview.svg" class="img-fluid" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="instructions-content">
          <h1>Instructions</h1>
          <hr>
          <ol>
            <li>Please ensure you are in a well lit space and your face is clearly visible on this camera.</li>
            <li>Please ensure you have good internet connectivity on your phone/laptop before initiate the process.</li>
            <li>Please speak clearly and sufficiently loudly to ensure your voice is recorded properly.</li>
            <li>Press the stop button only after completing all question. Once you press stop you can not go back.</li>
            <li>Close all camera and voice-related tools on your system, if they are open.</li>
            <li>Question will; appear on screen. Start answering after reading the question.</li>
            <li>There is a time limit for each question. Please be mindful of that.</li>
          </ol>
          <div class="btn-checkbox agree-check">
            <input name="agree" type="checkbox" id="agree" checked="checked">
            <label for="agree" class="form-check-label">I accept the <a href="#">Terms and Conditions</a></label>
          </div>
          <div class="form-group m-2">
            <button mat-button class="btn btn-primary" (click)="Recording()">Start recording your session</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>