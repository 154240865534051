<div class="app-loader" *ngIf="isLoading">
  <div class="spinner">
      <div class="double-bounce1 mat-bg-primary" style="background: #fcc02e"></div>
      <div class="double-bounce2 mat-bg-accent" style="background: #03a9f4"></div>
    </div>
</div>
<div class="modal-sec">
    <header class="mb-4">
      <h2 class="title-sm">Apply Job ({{this.jobDetail.technology}})</h2>
      <div class="dialog-close">
        <button mat-dialog-close (click)="CloseDialog()"><img src="assets/img/close.svg" alt=""></button>
      </div>
    </header>
    <div class="col-sm-3"  style="display:none;">
        <div class="cta ml-auto d-flex align-items-left">
          <input id="cvUpload" style="display: none;" type="file" #file (change)="uploadCV(file.files)" />
          <a class="btn btn-secondary mr-3" (click)="onCvUpload()">
            <i class="fa fa-upload" aria-hidden="true"></i>
            &nbsp; Upload Candidate With CV(PDF file)
          </a>
        </div>
    </div>

    <form  [formGroup]="candidateForm" (ngSubmit)="_onSubmit()">
        <div class="searchtoolbar form-listing ">
          <div class="row">
            <div class="col-sm-12">
                <div class="form-group mb-2">
                  <mat-form-field appearance="fill">
                    <mat-label>First Name </mat-label>
                    <input type="text" placeholder="Full Name" formControlName="firstName" aria-label="First Name" matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Last Name </mat-label>
                    <input type="text" placeholder="Full Name" formControlName="lastName" aria-label="Last Name" matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Mobile No. </mat-label>
                    <input type="number" placeholder="Full Name" formControlName="mobileNo" aria-label="Mobile Number" matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="text" placeholder="Full Name" formControlName="email" aria-label="Email-Id" matInput>
                  </mat-form-field>
                </div>
              </div>
          </div>
        </div>
        <div class="row" >
            <div class="col-sm-12">
              <div class="line-container" (click)="onCvUpload()">
                <div class="line"></div>
                <span class="icons" *ngIf="fileNameCV==''">Upload your CV  <i class="fa fa-cloud-upload" style="font-size: 19px;margin-left: 8px; margin-top: 2px;" aria-hidden="true"></i></span>
                <span class="iconsimg" *ngIf="fileNameCV!=''"> <img style="height: 40px;width: 40px;;" src="../../../../../assets/img/check-green.gif"> </span>
            </div>
            
              <div class="form-group mb-2" style="display: none;">
                <div class="dropzone" >
                  <h3 class="dropzone-head">Upload CV(.pdf)</h3>
                  <div class="text-wrapper">
                    <div class="centered">
                      <span>{{fileNameCV}}</span>
                      <label><span class="textLink" style="background: #56e036;">Choose file</span> 
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div> 
        <div class="text-center mt-2">
            <button mat-button type="button" class="btn btn-reset mr-4 apl-btn" (click)="CloseDialog()" >Cancel</button>
            <button type="submit" [disabled]="candidateForm.invalid" *ngIf="!isLoading" mat-button class="btn btn-primary apl-btn">Submit</button>
            <button type="button"  [disabled]="candidateForm.invalid" *ngIf="isLoading" mat-button class="btn btn-primary apl-btn">Submit</button>
        </div>

    </form>

   

  </div>
  