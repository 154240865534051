<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<script>
    $(document).ready(function () {
        $("#testimonial-slider").owlCarousel({

            items: 1,

            itemsDesktop: [1000, 1],

            itemsDesktopSmall: [979, 1],

            itemsTablet: [768, 1],

            pagination: true, 

            navigation: false,

            navigationText: ["", ""],

            slideSpeed: 1000,

            singleItem: true,

            transitionStyle: "fade",

            autoPlay: true

        });

    });

</script>


<section class="banner-section">
    <img src="../assets/img/fullstackdeveloperimg/home-banner.jpg" class="main-banner" />
    <div class="container">
        <div class="banner-caption">
            <div class="bg-white">
               <h1>Hire Java Developers to Boost Your Business</h1>
            </div>
            <p>
                Ready to make your business idea a reality but stuck because of a lack of expert technical teams? With the
                 rise of the greatest technological era in 2024, the ‘TalentOnLease’ team is always at the service 
                 of offering the best team of Java engineers. 
            </p>
            <p>
                Hire Java Developers on a part-time, full-time, or sub-contractual basis for easy hourly rates. Our
                 team includes the best talents around the globe. 
            </p>

            <div class="row email-box-main">
                <div class="col-md-6 mx-auto">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Java Developer Now</button>
                </div>
            </div>
            <div class="icon-text">
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/briefcase.png" alt="briefcase" />
                    <p>200 + Client Served</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/start-up.png" alt="Start Up" />
                    <p>500 + Java Project Delivered</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/check.png" alt="check" />
                    <p>7 Days Risk Free Trial</p>
                </div>
            </div>
        </div>
    </div>
</section>
 <div class="container">
    <header class="gradient-title-head mt-30 mb-30 col-md-8 mx-auto">
        <h2 class="title-lg text-center">Hire Java Developers – Talent at Your Fingertips</h2>
    </header>
    <ul class="hire-point">
        <li>
            The Indian IT segment has more than 7 Lakh Java Developers. Not all of them, but we have the best ones on our good list. Our team of Java developers are super experts of technology and love to take on new challenges as well as leave a mark of ultimate winning. 
        </li>
        <li>
            Hire dedicated Java developers with ‘TalentOnLease’ and let our team build your dream projects. Our certified team of Java developers ensures quality each time with exclusive experience in end-to-end Java Development. 
        </li>
        <li>
            With frontend & backend technologies like Java Spring Boot + React/ Vue/My SQL/Angular/Swagger/Kafka, our Java developers build agile software that offers scalability to companies.
        </li>
        <li>
            Enjoy premium services like software creation, integration, quality testing, premium support, and maintenance solutions at the hands of your fingertips. 
        </li>
    </ul>
</div> 
<section class="why-talentonlease">
    <div class="container position-relative" style="z-index: 1;">
        <div class="row">
            <div class="col-md-4">
                <img class="person-img" src="../../../../assets/img/talent-person.png"/>
            </div>
            <div class="col-md-8">
                <header class="gradient-title-head  mb-0 col-md-8 mx-auto">
                    <h2 class="title-lg text-center">Why <span>TalentOnLease?</span></h2>
                </header>
                <p class="text-center">Great things happen when great people come together. It’s magic!</p>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/strong-founding.svg"/></span>
                            Active bench of 7241
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/vetted-professionals.svg"/></span>
                            Verified and vetted IT professionals
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/acquisition-platform.svg"/></span>
                            Available Within 24 to 48 hours
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2">
                <button mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
            </div>
            </div>
        </div>
   
</div>

</section>



<h3 class="hire-sub-heading mt-30">
    Join 200+ companies who have trusted TalentOnLease for thier remote engineering
    needs.
</h3>
<div class="logo-section">
    <div class="slider">
        <div class="logos">
            <img src="{{leadingcompany.logo}}" *ngFor="let leadingcompany of leadingCompanies">
        </div>

    </div>
</div>
<div class="container mt-30">
    <div class="row">
        <p class="flex-center para text-center pad-15">
            Hiring a Java Developer from TalentOnLease ensures comprehensive expertise in both frontend and backend
            technologies, streamlined communication, and seamless integration, resulting in faster development cycles
            and cost-effective solutions for your project.
        </p>
        <div class="flex-center check flex-wrap">
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fast hiring within 24 to 48 hours</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Simple & Transparent Pricing</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fully Signed NDA & Agreement</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Regular Reporting & Transparent Communication</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Easy Exit Policy</p>
            </div>
        </div>
        <div class="hire-btn-section mx-auto">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Java Developer Now</button>
        </div>
    </div>
</div>

<section class="hire-second-section">
    <div class="container-fluid">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Hire <span>Java Developer</span></h2>
        </header>
        <div class="developer-main">
            <div class="row">
                <div class="col-lg-8">
<div class="hring-main-div">
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Mobin Arshad</h4>
                                        <p class="dev-title">software engineer(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>New Delhi</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3.6 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                   Mobin is a Java developer who can build both the front end and the back end of a website. 
                                   The front end refers to the parts of a website that users see and interact with, while the back end involves the behind-the-scenes data storage and processing.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Shastri Chavan</h4>
                                        <p class="dev-title">Java Developer(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.7 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                   Shastri is a skilled Java developer, adept in leveraging Java programming language to create robust and efficient software solutions. 
                                   With experti se in Java, Shastri contributes to developing applications ranging from web-based platforms to enterprise-level systems.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Umesh Vijay Sonune</h4>
                                        <p class="dev-title">Java Developer(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.3 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Umesh, as a Java Developer, specializes in creating software solutions using the Java programming language. 
                                   His expertise includes designing and implementing applications, contributing to both front end and back end development, and ensuring efficient and scalable code for various projects.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>                   
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Monali Lale</h4>
                                        <p class="dev-title">Java Developer(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.3 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Monali, as a Java Developer, is proficient in software development using the Java programming language. 
                                   She excels in creating robust applications, handling both front-end and back-end development tasks, and ensuring high-performance and scalable solutions for diverse projects.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Smita Ajay Chavan</h4>
                                        <p class="dev-title">Java Lead(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 12 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Smita, a Java developer, adeptly utilizes her skills in Java programming to create and maintain software applications. 
                                   Her expertise spans both front-end and back-end development, ensuring the seamless integration of user interfaces with efficient data processing and storage capabilities.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Surendra Kumar M</h4>
                                        <p class="dev-title">Java(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.3 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Surendra is a Java Developer skilled in designing and implementing software solutions using the Java programming language. 
                                   He specializes in both front-end and back-end development, adept at creating user-friendly interfaces and robust data processing systems for diverse applications.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Parbin Jose</h4>
                                        <p class="dev-title">Java(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Chennai</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 8 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Prabin, as a Java developer, possesses a wealth of experience and proficiency in leveraging Java for software development. He excels in both front-end and back-end tasks, adept at crafting intuitive user interfaces and implementing robust data processing functionalities. 
                                    His expertise ensures the delivery of scalable and efficient applications.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Sathvik C</h4>
                                        <p class="dev-title">Java(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 7.6 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Sathvik, proficient as a Java developer, specializes in designing and implementing software solutions using Java programming language. His expertise spans backend development, focusing on robust data management and processing capabilities. 
                                    Sathvik also excels in creating efficient algorithms and scalable applications, contributing significantly to the technological landscape with his skills.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Arpit Agnihotri</h4>
                                        <p class="dev-title">Java(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 8.9 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                   Arpit, a Java developer, adeptly applies his skills to design and develop software solutions using Java. With a strong background in both front-end and back-end development, Arpit excels in creating responsive user interfaces and implementing efficient data management systems, ensuring high-performance applications for diverse client needs.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Hemanth N</h4>
                                        <p class="dev-title">Java(Java)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.2 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                  Hemanth, a skilled Java developer, specializes in creating robust software solutions. With expertise in both front-end and back-end development, he designs intuitive user interfaces and implements efficient data processing systems. 
                                  Hemanth's experience ensures the delivery of high-quality, scalable applications tailored to meet various project requirements.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>                
                <div class="col-lg-4 bg-white">
                    <h4 class="title-md trust-title mt-3">Testimonial  </h4>
                    <div class="right-section">
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We hire about 16 Java Developers from TalentOnLease which
                                reduced our hiring effort by 90% as compared to
                                other venders.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/healthcare.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Managing Director MeasurePM, USA based Healthcare company
                                </p>
                            </div>
                        </div>
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We're super excited about TalentOnLease as we will scrap our existing
                                lengthy interview process and lean on TalentOnLease's vetting to build
                                up teams on demand.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/piggy-bank.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Director of Engineering Unikove Technologies, Software Service company
                                </p>
                            </div>
                        </div>
                        <div class="contact-form p-4 box-shadow trustpilot pb-0">
                            <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                                <h4>Contact us</h4>
                                <div class="form_group mt-4">
                                    <input type="text" name="name" value="" formControlName="name" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Fullname*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['name'].errors">
                                            <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                                <p class="text-danger">Full Name is Required</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="email" name="email" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Email*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                            <p class="text-danger">Email is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                            <p class="text-danger">Email is not valid</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" name="number" value="" size="40" class="form-control"
                                        aria-invalid="fase" placeholder="Phone*" formControlName="mobile"
                                        minlength="10" maxlength="10" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                        <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                                <p class="text-danger">mobile number is Required</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                                <p class="text-danger">mobile number should be number only</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                                <p class="text-danger">mobile number should be minimum 10 digit</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                                <p class="text-danger">mobile number should be maximum 10 digit</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="designation" name="designation" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Designation*"
                                        required="">
                                        <div *ngIf="contactflag && contactUsForm.controls['designation'].errors" class="mt-2">
                                            <div *ngIf="contactUsForm.controls['designation'].errors">
                                                <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                                    <p class="text-danger">Designation is Required</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                </div>
                    
                                <div class="form_group mt-3">
                                    <textarea name="messages" formControlName="message" class="form-control" required=""
                                        aria-invalid="fase" placeholder="Message" style="height:84px;"
                                        data-gramm="fase" wt-ignore-input="true"></textarea>
                                </div>
                                <div class="form_group text-right mt-4 mb-1 d-flex">
                                    <input type="submit" name="submit" value="Submit" class="btn-hire btn-block mr-2 mat-raised-button">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="why-hire-full-stack-section">
    <div class="container">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Why Hire Java Developers From TalentOnLease?</h2>
        </header>
        <p class="text-center">
            Hiring Java Developers with TalentOnLease is Easy! When you hire a Java Developer with
            TalentOnLease, it means peace of mind! We follow a transparent hiring process and well-defined processes on
            all our engagements.
        </p>
        <div class="why-full-stack-list">
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/thunder.png" /></span>
                        <h3>Transparent Hiring Models</h3>
                        <p>
                            We keep our hiring model transparent and with options to choose from. You don't need to stick with a hiring policy, nor do developers. Hire them as the best of what satisfies you both. Get your hands on the best employment culture.

                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/efficiency.png" /></span>
                        <h3>Experienced Developers All-the-time</h3>
                        <p>All our Java developers have years of exclusive experience. Based on their expertise, their charges may vary. However, we ensure that you will get a person who knows Java Development from end-to-end. </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/collaborate.png" /></span>
                        <h3>Quality Development</h3>
                        <p>Hire dedicated Java developers who are skilled in technologies like Java Spring Boot + React/ Vue/My SQL/Angular/Swagger/Kafka, LibGDX, and several other frameworks. Hire ‘TalentOnLease’ Java engineers and get quality development in quick time. </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/cost.png" /></span>
                        <h3>We’re Clients’ Favourite</h3>
                        <p>We have a record number of clients who have returned after their first project with us. That's how we’re making an impact. Understanding clients' unique needs and making their ideas a reality. 
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/innovation.png" /></span>
                        <h3>Easy Access to world-wide Developers</h3>
                        <p>‘TalentOnLease’ offers easy access to worldwide Java developers. Wider options mean better chances of a perfect deal, better experience, and portfolios. More to it, who doesn't love a little more options to taste?
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/verified-icon.png" /></span>
                        <h3>24/7 Support & Maintenance</h3>
                        <p>Each project from TalentOnLease comes with a support guarantee. Whether it's malware, a bug, or cyber-attack, we are always with you to maintain the continuous growth. 
                        </p>
                    </div>
                </div>
            </div>
           


        </div>
      
    </div>
</section>

<div class="container1">
    <div class="column1">
      <div class="text1">
          <h2 class="title1"> Technologies We Excels In: Hire Java Developers, Knowledged &
            Experienced.</h2>
          <p class="description1">We thrive on our technological excellence and our developers offer you the latest
            technologies, tools, framework, and systems to develop highly robust, secure, and
            user-friendly applications. Hire Java Developers from TalentOnLease and get versatile
            excellence on your desk.</p>
        </div>
      <div class="image1">
        <img src="assets/img/cccnnn.jpg" style="height: 600px; width: 100%;"/>
      </div>
    </div>
    <div class="column1" style="width: 700px;">
      
      <div class="technologies1">
        <h3>Technologies / Frameworks</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">Java SE</button>
          <button class="technologies-button1">Java+Web</button>
          <button class="technologies-button1">Java EE</button>
          <button class="technologies-button1">Java FX</button>
          <button class="technologies-button1">Swing</button>
          <button class="technologies-button1">Spring</button>
          <button class="technologies-button1">Boot</button>
          <button class="technologies-button1">Groovy/Grails</button>
          <button class="technologies-button1">Struts/SP</button>
          <button class="technologies-button1">JSF</button>
          <button class="technologies-button1">GWT</button>
          <button class="technologies-button1">ExtGWT</button>
          <button class="technologies-button1">Log4j</button>
          <button class="technologies-button1">Docker</button>
          <button class="technologies-button1">S3</button>
          <button class="technologies-button1">Lambda</button>
          <button class="technologies-button1">ELB</button>
          <button class="technologies-button1">Struts</button>
          <button class="technologies-button1">Hibernate</button>
          <button class="technologies-button1">EJB</button>
          <button class="technologies-button1">POI</button>
          <button class="technologies-button1">JAXB</button>
          <button class="technologies-button1">JSON</button>
          <button class="technologies-button1">Sencha GXT</button>
          <button class="technologies-button1">Primefaces</button>
          <button class="technologies-button1">Vaadin</button>
          <button class="technologies-button1">JasperReports</button>
        </div><hr>
        <h3>Application / Web Servers</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">JBoss</button>
          <button class="technologies-button1">GlassFishApache</button>
          <button class="technologies-button1">TomcatBEAN</button>
          <button class="technologies-button1">WebLogic</button>
          <button class="technologies-button1">ServerOracle</button>
          <button class="technologies-button1">Application Serve</button>
        </div><hr>
        <h3>Testing</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">JUnit</button>
          <button class="technologies-button1">TestNG</button>
          <button class="technologies-button1">JMeter</button>
          <button class="technologies-button1">Mockito</button>
          <button class="technologies-button1">EasyMock</button>
        </div><hr>
        <h3>Development Approaches</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">Scrum</button>
          <button class="technologies-button1">Kanban</button>
          <button class="technologies-button1">Iterative</button>
          <button class="technologies-button1">Waterfall</button>
          <button class="technologies-button1">DOD</button>
          <button class="technologies-button1">TDD</button>
          <button class="technologies-button1">BDD</button>
        </div><hr>
        <h3>Data Base</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">MS SQL</button>
          <button class="technologies-button1">PostgreSQL</button>
          <button class="technologies-button1">OradeH2</button>
        </div>
      </div>
    </div>
  </div>

<section class="how-hire-devloper-section">
    <div class="hhd-main">
        <div class="hhd-left mb-30 hhd-main-item">
            <div class="gradient-title-head my-5">
                <h2 class="title-lg text-center">How to Hire Quality <span>Java Developer</span></h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">1</div>
                            <div class="hire-div">
                                <h3>Define Job Requirements
                                </h3>
                                <p>Clearly outline the job responsibilities, qualifications, and skills required for the
                                    position.
                                    This
                                    information will form the basis for your job description</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">2</div>
                            <div class="hire-div">
                                <h3>Find Per Vetted
                                    Candidates
                                    from pool of TalentOnLease
                                </h3>
                                <p>Develop a compelling job description that includes key details about the position,
                                    responsibilites,
                                    qualifications, and any other relevant information.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">3</div>
                            <div class="hire-div">
                                <h3>Resume Screening
                                </h3>
                                <p>Review resumes to shortlist candidates who meet the basic qualifications.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">4</div>
                            <div class="hire-div">
                                <h3>Conduct
                                    Interviews
                                </h3>
                                <p>Organize interviews with key team members or stackholders to gather multiple
                                    perspectives on
                                    the
                                    candidate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">5</div>
                            <div class="hire-div">
                                <h3>Offer Rollout
                                </h3>
                                <p>Offer to the selected candidate with Immediate start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">6</div>
                            <div class="hire-div">
                                <h3>Background Check
                                </h3>
                                <p> Conduct background checks as necessary to verify the candidate's educational and
                                    professional
                                    background.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">7</div>
                            <div class="hire-div">
                                <h3>Onboarding
                                </h3>
                                <p> Sign Contract with TalentOnLease and welcome the new hire with a comprehensive
                                    onboarding
                                    process to
                                    ensure
                                    a smooth transition into their role and responsibility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">8</div>
                            <div class="hire-div">
                                <h3>Feedback and Continuous
                                    Improvement
                                </h3>
                                <p> We gather time to time feedback from client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>



<section class="major-key-section container">
    <div class="row">
        <header class="gradient-title-head mb-30 mx-auto">
            <h2 class="title-lg text-center">Benefits of Our Java Developer</h2>
        </header>
        <p class="mk-desc">
            Owing to years of industry experience and technology exposure, our full stack taskforce is well-competent at
            creating attractive web application and websites for you. They go a step ahead of the rest to unlock
            advantages obtained by full stack development.
        </p>
        <div class="mkb-section">
            <div class="mkb-section-items">
                <h3 class="heading">Our Developers are Digital Nomads</h3>
                <p class="desc">
                    Our Java Developers are Digital Nomads. They are extremely flexible with their work. The hybrid model of working allows them to embrace the changes followed by the excellent results. They’re experts in their jobs, operating in diverse circumstances. With extensive skills like React/ Vue/My SQL/Angular/Swagger/Kafka, they bring a dynamic approach to projects, giving them an edge against the competition. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">We Understand the Industry Demands</h3>
                <p class="desc">
                    Our team of Java Engineers understand the trends of the market and track forthcoming shifts. They stay tuned to evolving industry demands and deliver Java solutions which not only meet your expectations but go a step beyond that. Exceeding expectations, they ensure that your software is future-proof ready. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Get Access to a Hassle-free Management</h3>
                <p class="desc">
                    Give your company a power-booster Java development team with us. Integrate your workflow with world-class Java technology experts who effortlessly understand your requirements and process. Our team will make sure that everything goes in synchronisation with improved efficiency and collaboration.
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">No Hidden Cost is Our Favourite Policy</h3>
                <p class="desc">
                    ‘TalentOnLease’ follows a complete transparency policy for all employers. The projects will be discussed in a combined meeting and a balanced cost will be fixed in that. Our policies ensure trust and safety of your investment in the Java Development programs and developers recommend. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">See the Next-Gen Architecture</h3>
                <p class="desc">
                    We prioritise today but keep tomorrow in our minds. Our Java developers architect solutions using cloud services. For getting scalability, microservices are focused when developing any software which directly impacts your business scale. Express your ideas and our developers will meet IT business demands of the present & future as well. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Unparalleled Adaptability at Your Fingertips</h3>
                <p class="desc">
                    Our team of Java developers are experienced at catching the marketing trends. They are dedicated and have unbeaten adaptability. They offer a faster pace & quality at ease. Moreover, they will pick the best trendy choices to keep your software at the top of the competition. Simply, your product will have better chances of getting funds, boost, recognition, and sales. 
                </p>
            </div>
        </div>
    </div>
</section>
<section class="newbox" style="width: 95%; margin-left: 50px; display: flex;" >
    <div>
        <h2> Who is a Java Developer?</h2>
    <p>A <b>Java Developer</b> is a software professional who specializes in designing, developing, and
        managing applications using the Java programming language. Java developers are
        responsible for building scalable, efficient, and robust software solutions, including web
        applications, enterprise-level systems, and software tools.<br><br>
    
        With hands-on experience across web development, mobile applications, side projects, and
        even game development, <b>TalentOnLease Java Developers</b> are fully equipped to bring your
        dream project to life.<br><br>

        Our Java developers specialize in creating applications for a wide range of platforms,
        including computers, servers, and mobile devices. Whether it’s a large-scale enterprise
        solution or a more focused application, our team delivers high-performance, scalable, and
        tailored results to meet your specific needs.</p><br><br>

    <h2>Hire Java Developer: Enriched with Trending Skills</h2>
    <p>Want to hire Java developers, skilled and experienced programmers to create your project?
        Our Java team members possess in-depth knowledge & have practical experience with Java
        development projects with expertise in scalable microservices, web applications, and APIs.
        Here is a list of skills we master:</p><br>
        <ul>
            <li> Spring Boot Developer</li>
            <li>Hibernate Developer</li>
            <li>Java Microservices Developer</li>
            <li>Full Stack Java Developer</li>
            <li>JEE Developer</li>
            <li>Play Developer</li>
            <li>Java Backend Developer</li>
            <li>GWT Developer</li>
        </ul>
    </div>
    <div style="height: 150px;width: 75%; background-color: rgb(212, 212, 212);margin-left: 100px;padding: 10px;border-radius: 10px; margin-bottom: 10px;">
        <p style="font-size: 20px;">Java Developer Hiring resources</p>
        <p style="font-size: 14px;color: green;">Learn about cost factors</p>
        <button mat-raised-button (click)="openDialog()"  style="background-color: green; color: white;border-radius: 10px;border: none;">Hire talent</button>
    </div>    
    

</section> 
<section class="section faqs-page-sec">
    <div class="container">
        <header class="gradient-title-head text-center">
            <small class="small-head">How we can help?</small>
            <h2 class="title-lg">Frequently Asked <span>Questions</span></h2>
        </header>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/fullstackdeveloperimg/faq.png" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="tabbing-faq-sec">
                    <div class="tabbing-body">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Where can I hire a dedicated Java developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>You can hire a dedicated Java developer from TalentOnLease, where we have a pool of experienced Java developers who are ready to work on your different projects. 
                                    You can visit our website and checkout our team developers and hire the ones that align with your needs. 
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Do you have certified Java developers?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Yes, we have certified Java developers with expertise in different Java frameworks and technologies ensuring perfect solutions for your projects.
                                     All our developers are certified and we conduct a perfect interview to check everything. 
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How much does it cost to hire a certified Java developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>The cost to hire a certified Java developer will depend on different factors like experience expertise and project requirements. 
                                    You can connect with us for a personalized quotation tailored to your requirements.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Will the hired Java developer work according to my work zone?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Yes, our hired Java developers can adapt to your work zone and schedule ensuring perfect communication and collaboration irrespective of your time zones.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How does your Java developer hiring process work?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Our Java developer hiring process includes understanding your project requirements, shortlisting suitable candidates, conducting some interviews, and selecting the perfect fit for your project.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What should I look for when hiring a Java developer?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>When you're hiring a Java developer you need to consider factors like experience expertise in Java frameworks, problem-solving skills, and compatibility within your project team.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>What qualifications and experience do your Java developers possess?
                                   </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Java developers possess relevant qualifications like proficiency in Java programming language, including its core concepts, data structures, and algorithms, and extensive experience in Java development like expertise and frameworks like spring.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Would you replace the resource if it doesn't perform well?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Our developers are truly the best and you don't need to generally change. But Yes if the hired developer doesn't meet the performance expectations we can provide you some options for resource replacement to ensure the success of your project.
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="ready-get-started-section">
    <header class="gradient-title-head mb-30">
        <h3 class="title-lg text-center">Get Started</h3>
    </header>
    <p>
        Build any idea and turn it into reality with us. Get the best java developers for hire in India.
    </p>
    <p>Flexible Working | Customised Solution | Unmatched Speed</p>
    
    <button mat-raised-button (click)="openDialog()" class="mat-focus-indicator btn btn-primary mat-button-base mb-15">Contact Us Now</button>
</section>
<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
    aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
    aria-hidden="true"></i></a>

</div>