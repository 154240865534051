import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { APIConfig } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_config/api.service';
import { HomeService } from 'src/app/_services';
import { CandidateService } from 'src/app/_services/candidate.service';
import { MasterService } from 'src/app/_services/master.service';
import { BaseComponent } from 'src/app/Components/shared/base-component/base.component';
import { BulkUploadCvComponent } from 'src/app/Components/shared/bulk-upload-cv/bulk-upload-cv.component';

@Component({
  selector: 'app-apply-job-dialog',
  templateUrl: './apply-job-dialog.component.html',
  styleUrls: ['./apply-job-dialog.component.css']
})
export class ApplyJobDialogComponent extends BaseComponent implements  OnInit {
  
  candidateForm: FormGroup = new FormGroup({});
  formData: FormData = new FormData();
  jobDetail:any ;
  cities: any;
  isLoading = false;
  fileNameCV: string = "";
  candidateDetail:any;
  partnerId:number= 0;

  constructor( 
    private formBuilder: FormBuilder,sessionService: LocalStorageService,
    private masterService: MasterService, private toastrService: ToastrService,
     private homeService: HomeService,
    private _mdr: MatDialogRef<BulkUploadCvComponent>,
    private service:ApiService,
    @Inject(MAT_DIALOG_DATA) data: any,) {
      
      super(sessionService);
      this.jobDetail = data;
      this.fileNameCV='';
      this.candidateForm = this.formBuilder.group({
        firstName: ['',Validators.required],
        lastName: ['',Validators.required],
        mobileNo: ['',Validators.required],
        email: ['',Validators.required]
      });
   }
  ngOnInit(): void {
    this.getAllCities();
  }

  getAllCities() {
    this.masterService.getcities(0).subscribe(result => {
      if (result && result.Data != null) {
        this.cities = result.Data;
      }
    })
  }
  CloseDialog() {
    this._mdr.close(false);
    this.fileNameCV='';
  }

  _onSubmit(){
    if(document.getElementById('cvUpload')['files'][0] ==undefined ){
      this.toastrService.warning('Please upload your cv in .pdf format!','Warning');
      return;
    }
    let jobId = this.jobDetail.id ;
    let positionType = this.jobDetail.positionType;
    let technologyId = this.jobDetail.technologyId;
    this.isLoading = true;
     this.formData.append("jobId", jobId,);
     this.formData.append("firstName", this.candidateForm.controls["firstName"].value);
     this.formData.append("lastName", this.candidateForm.controls["lastName"].value);
     this.formData.append("mobileNo", this.candidateForm.controls["mobileNo"].value);
     this.formData.append("email", this.candidateForm.controls["email"].value);
     this.formData.append("preferredLocation", this.jobDetail.cityId);
     this.formData.append("positionType", positionType);
     this.formData.append("technologyId", technologyId);
    // this.candidateService.bulkUploadCVFiles(formData,partnerId,technologyId).subscribe((result: any) => {
    this.homeService.saveCandidateAndApply(this.formData,jobId, this.candidateForm.value,positionType,technologyId,this.jobDetail.cityId)
      .subscribe((res: any) => {
        this.fileNameCV='';
        this.isLoading = false;
        if (res.StatusCode != 200) {
          this.toastrService.error("There is some error occured. Please try after some time.");
        }
        else {
          this.toastrService.success("Thanks for applying this job. Our team will connect you shortly.");
        }
        this.CloseDialog();
      }, err => {
        this.fileNameCV='';
        this.isLoading = false;
        this.CloseDialog();
      });
  }

  onCvUpload() {
      document.getElementById('cvUpload')?.click();
  }
  
  async uploadCV(event: any) {
    if (event[0].type !== 'application/pdf') {
      this.toastrService.error("Only PDF file allowed");
      this.isLoading = false;
      return;
    }
    this.formData.append("file", event[0]);
    this.fileNameCV=event[0].name;
  } 

}
