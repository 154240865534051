<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css">
<script>
    $(document).ready(function () {
        $("#testimonial-slider").owlCarousel({

            items: 1,

            itemsDesktop: [1000, 1],

            itemsDesktopSmall: [979, 1],

            itemsTablet: [768, 1],

            pagination: true,

            navigation: false,

            navigationText: ["", ""],

            slideSpeed: 1000,

            singleItem: true,

            transitionStyle: "fade",

            autoPlay: true

        });

    });

</script>


<section class="banner-section">
    <img src="../assets/img/fullstackdeveloperimg/home-banner.jpg" class="main-banner" />
    <div class="container">
        <div class="banner-caption">
            <div class="bg-white"><h1>Hire Python Developers for Your Projects</h1>
            </div>
            <p style="font-size: 1rem;" > Looking to hire Python developers? TalentOnLease has a wide pool of expert Python developers, ready to join your team and elevate your project. Our developers are trusted Python experts with pro-level skills in frameworks like Django, Flask, FastAPI, and more, enabling the development of enterprise-grade web apps. They also bring strong OOP knowledge and expertise in AI/ML solutions.</p>
            <p>Work with the Top-Rated Python Developers with talentOnLease.</p>
            <ul style="color: white; font-size: 1rem;">
                <li>Timesheets for proof of work.</li>
                <li>IP Rights & NDA agreement.</li>
                <li>Flexible contracts, and transparent pricing.</li>
                <li>Free trial and quick setup with developers.</li>
                <li>Tailored Python development solutions.</li>
            </ul>
            <div class="row email-box-main">
                <div class="col-md-6 mx-auto">
                    <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Python Developer Now</button>
                </div>
            </div>
            <div class="icon-text">
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/briefcase.png" alt="briefcase" />
                    <p>200 + Client Served</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/start-up.png" alt="Start Up" />
                    <p>500 + Python Project Delivered</p>
                </div>
                <div class="icon-item">
                    <img src="../assets/img/fullstackdeveloperimg/check.png" alt="check" />
                    <p>7 Days Risk Free Trial</p>
                </div>
            </div>
        </div>
    </div>
</section>
 <div class="container">
    <header class="gradient-title-head mt-30 mb-30 col-md-8 mx-auto">
        <h2 class="title-lg text-center"><b>Hire Python Developers with Ease: Work with the Experts</b></h2>
    </header>
    <ul class="hire-point">
        <li>
            Hire pre-vetted Python developers and get your business expert’s assistance who are skilled in OOP capabilities, Django, Flask, Web2py, Machine Learning, Tornado, etc. Enjoy the luxury of years of expertise, offering development & design skills from the experts only. 
        </li>
        <li>
            TalentOnLease offers you a wide pool, consisting only of the top players in the industry. We offer you technological excellence at the ease of your fingertips in the fastest time possible. You save 40-50% of the total cost, enjoy remote flexible hiring models for Python developers, and have professional space for healthy collaborations. 
        </li>
        <li>
            What should you expect from us? Hassle-free onboarding, Advanced technological excellence, Dedication towards your vision, and commitment to simple on-point solutions.
        </li>
       
    </ul>
</div> 
<section class="why-talentonlease">
    <div class="container position-relative" style="z-index: 1;">
        <div class="row">
            <div class="col-md-4">
                <img class="person-img" src="../../../../assets/img/talent-person.png"/>
            </div>
            <div class="col-md-8">
                <header class="gradient-title-head  mb-0 col-md-8 mx-auto">
                    <h2 class="title-lg text-center">Why <span>TalentOnLease?</span></h2>
                </header>
                <p class="text-center">Great things happen when great people come together. It’s magic!</p>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/strong-founding.svg"/></span>
                            Active bench of 7241
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/vetted-professionals.svg"/></span>
                            Verified and vetted IT professionals
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="w-icon">
                            <span><img src="../../../../assets/img/acquisition-platform.svg"/></span>
                            Available Within 24 to 48 hours
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2">
                <button mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
            </div>
            </div>
        </div>
   
</div>

</section>



<h3 class="hire-sub-heading mt-30">
    Join 200+ companies who have trusted TalentOnLease for thier remote engineering
    needs.
</h3>
<div class="logo-section">
    <div class="slider">
        <div class="logos">
            <img src="{{leadingcompany.logo}}" *ngFor="let leadingcompany of leadingCompanies">
        </div>

    </div>
</div>
<div class="container mt-30">
    <div class="row">
        <p class="flex-center para text-center pad-15">
            Hiring a Python Developer from TalentOnLease ensures comprehensive expertise in both frontend and backend
            technologies, streamlined communication, and seamless integration, resulting in faster development cycles
            and cost-effective solutions for your project.
        </p>
        <div class="flex-center check flex-wrap">
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fast hiring within 24 to 48 hours</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Simple & Transparent Pricing</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Fully Signed NDA & Agreement</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Regular Reporting & Transparent Communication</p>
            </div>
            <div class="check-item">
                <img src="../assets/img/fullstackdeveloperimg/check-circle.png" alt="check-circle" />
                <p class="para">Easy Exit Policy</p>
            </div>
        </div>
        <div class="hire-btn-section mx-auto">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Python Developer Now</button>
        </div>
    </div>
</div>

<section class="hire-second-section">
    <div class="container-fluid">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center"><b>Hire the Best Python Developers Based on Your Project Requirements</b></h2>
            <p style="text-align: center;"> Have a custom project dedicated to solving a problem and fulfilling a vision? We got you here! TalentOnLease allows you to hire Python developers who suit your needs the best. 
            </p>
        </header>
        <div class="developer-main">
            <div class="row">
                <div class="col-lg-8">
                    <div class="hring-main-div">
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Tushar Babarao</h4>
                                        <p class="dev-title">Quality Assurance</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3.8 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                   Tushar is a seasoned Python Developer adept at leveraging the language for diverse applications including web development, data analysis, and automation. He excels in designing and implementing efficient algorithms, optimizing code performance, and deploying scalable solutions. 
                                   Tushar's expertise extends to integrating Python with frameworks like Django and Flask, ensuring robust and secure applications that meet client needs effectively.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Aditya Sonwane</h4>
                                        <p class="dev-title">Quality Assurance(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3.1 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>
                                    </div>
                                </div>
                                <p class="desc">
                                    Aditya is a versatile Python Developer specializing in web development, data science, and automation. He excels in leveraging Python libraries and frameworks such as Django and Flask to build scalable and efficient applications. 
                                    Aditya's proficiency extends to machine learning and AI, where he applies Python for predictive analytics and natural language processing solutions, driving innovation and delivering impactful results.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Vaibhav Marathe</h4>
                                        <p class="dev-title">Quality Assurance(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3.1 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Vaibhav is an innovative Python Developer proficient in creating scalable web applications and leveraging Django and Flask frameworks. He excels in data analysis and machine learning, applying Python for predictive modeling and AI solutions. 
                                    Vaibhav is dedicated to optimizing application performance and implementing robust cybersecurity measures, ensuring reliable and secure software deployments that exceed client expectations.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Kamran Imtiyaz</h4>
                                        <p class="dev-title">Software Developer(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Noida</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Kamran is a Python Developer with expertise in building scalable web applications using Django and Flask frameworks. He excels in data-driven solutions, applying Python for data analysis, machine learning, and AI implementations. 
                                    Kamran's commitment to continuous learning drives him to explore emerging technologies, ensuring he delivers innovative solutions that address complex business challenges effectively.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Pallavi Shinde</h4>
                                        <p class="dev-title">Data QualityAssurance(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Mumbai</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Pallavi is a proficient Python Developer skilled in creating robust web applications using Django and Flask frameworks. 
                                    She specializes in data science and machine learning, harnessing Python for predictive analytics and natural language processing. Pallavi's expertise includes optimizing application performance, implementing scalable solutions, and integrating advanced features to deliver impactful software solutions that drive business growth.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Harshali Bachhav</h4>
                                        <p class="dev-title">Data Quality Assurance(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Mumbai</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 3.4 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Harshali is an accomplished Python Developer proficient in developing scalable web applications using Django and Flask frameworks. She specializes in data engineering and cloud computing, utilizing Python for ETL processes and deploying solutions on AWS and Azure. 
                                    Harshali excels in optimizing application performance, implementing microservices architecture, and leveraging AI technologies for innovative software solutions that meet complex business needs.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Shubh S</h4>
                                        <p class="dev-title">Software Engineer</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Python</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 4.5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Shubh is a versatile Python Developer adept at building robust web applications with Django and Flask frameworks. His expertise extends to data science and machine learning, where he applies Python for predictive modeling and AI solutions. 
                                    Shubh is committed to continuous improvement, exploring emerging technologies like serverless computing and blockchain integration to deliver innovative and scalable software solutions.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Annapurna Kalpesh Salunke</h4>
                                        <p class="dev-title">Data Quality Assurance(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Pune</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Annapurna is an experienced Python Developer specializing in web development with Django and Flask frameworks. She excels in data analysis and visualization, leveraging Python for insightful business intelligence solutions. 
                                    Annapurna's proficiency extends to cloud computing and DevOps, ensuring scalable and secure deployments on platforms like AWS and Azure. She is dedicated to delivering high-performance applications that drive operational efficiency and user satisfaction.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Rocky Kumar</h4>
                                        <p class="dev-title">Python Developer(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 8 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Rocky is a dynamic Python Developer proficient in creating versatile applications with Django and Flask frameworks. His expertise spans machine learning and natural language processing, utilizing Python for predictive analytics and AI-driven solutions. 
                                    Rocky excels in optimizing application performance and implementing scalable architectures, including microservices and serverless computing. His passion for innovation drives him to explore emerging technologies, ensuring cutting-edge software solutions that meet diverse business challenges effectively.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-section flex-left flex-col">
                        <div class="d-flex flex-column flex-md-row">
                            <i class="fa fa-user"></i>
                            <div class="flex-left flex-col">
                                <div class="dev-title-main flex-left flex-row">
                                    <div class="col-9">
                                        <h4 class="name">Pravas Patra</h4>
                                        <p class="dev-title">Python Developer(Python)</p>
                                        <div class="loc-date flex-left">
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/location.png" alt=""
                                                    class="icon" />
                                                <p>Bangalore</p>
                                            </div>
                                            <div class="loc-date-item flex-left">
                                                <img src="assets/img/fullstackdeveloperimg/calendar.png" alt=""
                                                    class="icon" />
                                                <p>Total Exp 5 Years</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button mat-raised-button (click)="openDialog()" class="btn hire-btn">Hire</button>


                                    </div>
                                </div>
                                <p class="desc">
                                    Pravas is a skilled Python Developer specializing in backend web development using Django and Flask frameworks. He excels in designing and optimizing APIs, ensuring robust and scalable solutions. 
                                    Pravas is proficient in data engineering, employing Python for ETL processes and implementing cloud-based solutions for efficient data management. His commitment to continuous learning and problem-solving enables him to deliver innovative software solutions that drive business growth.
                                </p>
                            </div>
                        </div>
                        <div class="expert-in-main flex-left flex-row row">
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <img src="assets/img/fullstackdeveloperimg/star.png" alt="" />
                                    <h4>Expert in</h4>
                                </div>
                                <div class="expert">
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Redux</span>
                                    <span>Webpack</span>
                                    <span>MySQL</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="exp-heading flex-left flex-row">
                                    <h4>Also worked with</h4>
                                </div>
                                <div class="expert">
                                    <span>Typescript</span>
                                    <span>JavaScript</span>
                                    <span>jQuery</span>
                                    <span>Express.js</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-4 bg-white">
                    <h4 class="title-md trust-title mt-3">Testimonial  </h4>
                    <div class="right-section">
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We hire about 16 Python Developers from TalentOnLease which
                                reduced our hiring effort by 90% as compared to
                                other venders.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/healthcare.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Managing Director MeasurePM, USA based Healthcare company
                                </p>
                            </div>
                        </div>
                        <div class="box-shadow trustpilot">
                            <p class="quote-open">
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                            </p>
                            <p class="trust-desc">
                                We're super excited about TalentOnLease as we will scrap our existing
                                lengthy interview process and lean on TalentOnLease's vetting to build
                                up teams on demand.
                            </p>
                            <div class="signature">
                                <div class="sign-icon">
                                    <img src="assets/img/fullstackdeveloperimg/piggy-bank.png" alt="sign-icon">
                                </div>
                                <p class="sign-desc">
                                    Director of Engineering Unikove Technologies, Software Service company
                                </p>
                            </div>
                        </div>
                        <div class="contact-form p-4 box-shadow trustpilot pb-0">
                            <form [formGroup]="contactUsForm" (ngSubmit)="submiContactUs()">
                                <h4>Contact us</h4>
                                <div class="form_group mt-4">
                                    <input type="text" name="name" value="" formControlName="name" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Fullname*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['name'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['name'].errors">
                                            <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                                                <p class="text-danger">Full Name is Required</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="email" name="email" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Email*"
                                        required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['email'].errors" class="mt-2">
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                                            <p class="text-danger">Email is Required</p>
                                        </div>
                                        <div *ngIf="contactUsForm.controls['email'].errors?.['email']">
                                            <p class="text-danger">Email is not valid</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" name="number" value="" size="40" class="form-control"
                                        aria-invalid="fase" placeholder="Phone*" formControlName="mobile"
                                        minlength="10" maxlength="10" required="">
                                    <div *ngIf="contactflag && contactUsForm.controls['mobile'].errors">
                                        <div *ngIf="contactUsForm.controls['mobile'].errors" class="mt-2">
                                            <!-- <p class="text-danger">Mobile Number is Required</p> -->
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['required']">
                                                <p class="text-danger">mobile number is Required</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['pattern']">
                                                <p class="text-danger">mobile number should be number only</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['minlength']">
                                                <p class="text-danger">mobile number should be minimum 10 digit</p>
                                            </div>
                                            <div *ngIf="contactUsForm.controls['mobile'].errors?.['maxlength']">
                                                <p class="text-danger">mobile number should be maximum 10 digit</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                                <div class="form_group mt-3">
                                    <input type="text" formControlName="designation" name="designation" value="" size="40"
                                        class="form-control" aria-invalid="fase" placeholder="Designation*"
                                        required="">
                                        <div *ngIf="contactflag && contactUsForm.controls['designation'].errors" class="mt-2">
                                            <div *ngIf="contactUsForm.controls['designation'].errors">
                                                <div *ngIf="contactUsForm.controls['designation'].errors?.['required']">
                                                    <p class="text-danger">Designation is Required</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                </div>
                    
                                <div class="form_group mt-3">
                                    <textarea name="messages" formControlName="message" class="form-control" required=""
                                        aria-invalid="fase" placeholder="Message" style="height:84px;"
                                        data-gramm="fase" wt-ignore-input="true"></textarea>
                                </div>
                                <div class="form_group text-right mt-4 mb-1 d-flex">
                                    <input type="submit" name="submit" value="Submit" class="btn-hire btn-block mr-2 mat-raised-button">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="why-hire-full-stack-section">
    <div class="container">
        <header class="gradient-title-head mb-30">
            <h2 class="title-lg text-center">Why Choose TalentOnLease? </h2>
        </header>
        <p class="text-center">
            Transparent Contracts, Simplest Process, Fastest Hiring, and Peace of Mind- TalentOnLease offers you the best deals to Hire Python Development Experts.
        </p>
        <div class="why-full-stack-list">
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/thunder.png" /></span>
                        <h3>Experienced Developers</h3>
                        <p>Get your hands on the best Python developers with us. Our developers have been serving businesses, creating solutions based on custom requirements for each project, offering them the desired results faster and with quality. Whether it’s applications, web pages, or enterprise-level software, hire the rich talent on your board with ease.  </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/efficiency.png" /></span>
                        <h3>Transparent Hiring Models</h3>
                        <p>We don’t believe in hiding the models we work on. At TalentOnLease, we work in transparent models, at the terms of each client, preparing custom project guidelines & contracts to offer maximum satisfaction to all. We focus on quality in design, UI/UX advancements, strong code development, and adaptability as the core of any hiring.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/collaborate.png" /></span>
                        <h3>Quality Development</h3>
                        <p>Get yourself the highest quality of Python programming development at remote operational working models. We make sure that all your projects are completed on time with no bugs at all. Our developers easily integrate with your in-house team when needed to offer a collaborative working domain for quality work.  </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/cost.png" /></span>
                        <h3>High Client Retention</h3>
                        <p>Why choose us? We make the best of professional deals, maintaining the business code of conduct for all time. You get to deal with custom desired results and developers with flexible working models to choose what is best for you. We score a 90% satisfaction rate and 6 out of them have returned to us for other development projects. 

                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/innovation.png" /></span>
                        <h3>Experienced QA</h3>
                        <p>When we talk about quality, we mean it. Our specialised QA testing team make sure to analyse products once it’s done. We intensely check for the bugs & errors to remove them. From design to development, code quality to presentation, we cross-check your projects to make them results-oriented and profitable in business numbers. 
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="wfs-list-item">
                        <span class="icon"><img src="assets/img/fullstackdeveloperimg/verified-icon.png" /></span>
                        <h3> Support & Maintenance</h3>
                        <p>Every time you build a Python solution with us, there comes an exclusive professional support & maintenance system for the next 30 days free of cost. The support & maintenance team registers your queries/issues/redevelopment and the same team operates on them. This makes the operations consistent and removes all acts of potential misplacement of ideas. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
</section>
<div class="container1">
    <div class="column1">
      <div class="text1">
          <h2 class="title1"> Technologies Our Python Developer Excels In</h2>
          <p class="description1">We thrive on our technological excellence and our developers offer you the latest
            technologies, tools, framework, and systems to develop highly robust, secure, and
            user-friendly applications. Hire Python Developers from TalentOnLease and get versatile
            excellence on your desk.</p>
        </div>
      <div class="image1">
        <img src="assets/img/cccnnn.jpg" style="height: 800px; width: 100%;;"/>
      </div>
    </div>
    <div class="column1" style="width: 700px;">
      
      <div class="technologies1">
        <h3 class="techhead">Technologies / Frameworks</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">Django</button>
          <button class="technologies-button1">Pyramid</button>
          <button class="technologies-button1">Flask</button>
          <button class="technologies-button1">Web2py</button>
          <button class="technologies-button1">Tornado</button>
          <button class="technologies-button1">Bottle</button>
        </div><hr>
        <h3 class="techhead">Libraries</h3>   
        <div class="technologies-list1">
          <button class="technologies-button1">Fabric</button>
          <button class="technologies-button1">Requests</button>
          <button class="technologies-button1">Gdata</button>
          <button class="technologies-button1">Pika</button>
          <button class="technologies-button1">Pillow/PIL</button>
          <button class="technologies-button1">NetowrkX</button>
          <button class="technologies-button1">wxPython</button>
          <button class="technologies-button1">Scrappy</button>
        </div><hr>
        <h3 class="techhead">Tools & Utilise</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">South</button>
          <button class="technologies-button1">Asyncio</button>
          <button class="technologies-button1">Beautiful Soap</button>
          <button class="technologies-button1">NumPy</button>
          <button class="technologies-button1">Celery</button>
          <button class="technologies-button1">Factory Boy</button>
          <button class="technologies-button1">Pycrypto</button>
          <button class="technologies-button1">Factory Boy</button>
          <button class="technologies-button1">Sqlalchemy</button>
          <button class="technologies-button1">Memcached</button>
          <button class="technologies-button1">PuQt</button>
          <button class="technologies-button1">Gunicorn</button>
          <button class="technologies-button1">Virtualenv</button>
        </div><hr>
        <h3 class="techhead">Databases</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">Oracle</button>
          <button class="technologies-button1">MySQL</button>
          <button class="technologies-button1">SQLite</button>
          <button class="technologies-button1">MongoDB</button>
          <button class="technologies-button1">MS SQL</button>
          <button class="technologies-button1">Redis</button>
          <button class="technologies-button1">Postgres</button>
        </div><hr>
        <h3 class="techhead">PM Tools & Testing + ORMs</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">Trello</button>
          <button class="technologies-button1">Slack</button>
          <button class="technologies-button1">Pytest</button>
          <button class="technologies-button1">QLAlchemy</button>
        </div><hr>
        <h3 class="techhead">General Framework & Libraries</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">Pillow</button>
          <button class="technologies-button1">Scrappy</button>
          <button class="technologies-button1">Requests</button>
        </div><hr>
        <h3 class="techhead">Editors’ IDES</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">VS Code</button>
          <button class="technologies-button1">PyCharm Community Edition</button>
          <button class="technologies-button1">PyCharm Professional Edition</button>
        </div><hr>
        <h3 class="techhead">CMS & Deployment Process</h3>
        <div class="technologies-list1">
          <button class="technologies-button1">CI/CD</button>
          <button class="technologies-button1">Wagtail</button>
        </div>
      </div>
    </div>
</div>
<section class="how-hire-devloper-section">
    <div class="hhd-main">
        <div class="hhd-left mb-30 hhd-main-item">
            <div class="gradient-title-head my-5">
                <h2 class="title-lg text-center">How to Hire Quality <span>Python Developer</span></h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">1</div>
                            <div class="hire-div">
                                <h3>Define Job Requirements
                                </h3>
                                <p>Clearly outline the job responsibilities, qualifications, and skills required for the
                                    position.
                                    This
                                    information will form the basis for your job description</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">2</div>
                            <div class="hire-div">
                                <h3>Find Per Vetted
                                    Candidates
                                    from pool of TalentOnLease
                                </h3>
                                <p>Develop a compelling job description that includes key details about the position,
                                    responsibilites,
                                    qualifications, and any other relevant information.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">3</div>
                            <div class="hire-div">
                                <h3>Resume Screening
                                </h3>
                                <p>Review resumes to shortlist candidates who meet the basic qualifications.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">4</div>
                            <div class="hire-div">
                                <h3>Conduct
                                    Interviews
                                </h3>
                                <p>Organize interviews with key team members or stackholders to gather multiple
                                    perspectives on
                                    the
                                    candidate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">5</div>
                            <div class="hire-div">
                                <h3>Offer Rollout
                                </h3>
                                <p>Offer to the selected candidate with Immediate start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">6</div>
                            <div class="hire-div">
                                <h3>Background Check
                                </h3>
                                <p> Conduct background checks as necessary to verify the candidate's educational and
                                    professional
                                    background.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">7</div>
                            <div class="hire-div">
                                <h3>Onboarding
                                </h3>
                                <p> Sign Contract with TalentOnLease and welcome the new hire with a comprehensive
                                    onboarding
                                    process to
                                    ensure
                                    a smooth transition into their role and responsibility.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="hire-list">
                            <div class="num">8</div>
                            <div class="hire-div">
                                <h3>Feedback and Continuous
                                    Improvement
                                </h3>
                                <p> We gather time to time feedback from client to check the performance of the
                                    candidate.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
 <section class="major-key-section container">
    <div class="row">
        <header class="gradient-title-head mb-30 mx-auto">
            <h2 class="title-lg text-center">Hire Python Developers through TalentOnLease - What's The Benefits?
            </h2>
        </header>
        <p class="mk-desc">
            With years of experience in Industry and Technology, TalentOnLease offers you to hire Python developers with industry expertise. Our well-competent task force embraces all skills for creating attractive & personalised web applications & websites.
        </p>
        <div class="mkb-section">
            <div class="mkb-section-items">
                <h3 class="heading">Rapid Development</h3>
                <p class="desc">
                    TalentOnLease is famous for the on-time development of the solutions that are demanded from the clients. Our skilled team of Python developers leads to the fastest designing, development from scratch, and maintenance which provides you an upper hand against your competitors. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading"> Enhanced Security </h3>
                <p class="desc">
                    Hire Python developers who understand the need for loyalty & security at the enterprise level. We provide each of our developers with a non-disclosure agreement customised to your requirements. At TalentonLease, we make sure not to leave any stone unturned for implying robust security measures with strict policies, penalties, and code repositories with our developers. 

                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Customised Solutions</h3>
                <p class="desc">
                    No fixed solution can match up to the need for a customised solution for each business. We create special custom projects for individual business people and enterprises across 100+ industries. These custom solutions in websites, applications, cloud computing, business management, and machine learning give you an edge in the market. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Time Zone and Language Alignment</h3>
                <p class="desc">
                    We operate with developers who are compatible to work with any time zone. Bring a team together who would be working with your in-house team with flexible working hours with us. Not only this but we offer proper communication channels, native developers collaboration, and different working models for better productivity & workflow. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Industry Expertise</h3>
                <p class="desc">
                    In only 3 years of experience, we have already served 200+ of clients across 100+ industries, catered to their demands and gave them solutions related to WP, Python with ML, UI Path, Android Native, Flask, Web2py, machine learning, and Tornado like advanced technologies. More to that, we even add our useful insights to enhancing a project’s operational domain, user interaction, and engagement. 
                </p>
            </div>
            <div class="mkb-section-items">
                <h3 class="heading">Cost-Effective</h3>
                <p class="desc">
                    We offer the best of cost-effective deals on each solution quotation and make no compromise on the quality we promise. With the most skilled developers in our team, we reduce your cost by up to 60% of your total expenditure which opens up further liberty to scaling projects for more operative domains. 
                </p>
            </div>
        </div>
    </div>
</section>
<section class="newbox" style="display: flex;">
    <div>
        <h2>What is a Python Developer?</h2>
        <p> A Python developer is an individual proficient in all the skills needed in creating, designing, and delivering web apps & programs with Python language. He/She is responsible for efficient Python development for custom Python programming services as demanded by the project. Moreover, they are also demanded to identify & debug Python projects.
         </p>
         <h3> Why Should You Hire Python Developers?</h3>
        <p> Python developers offer you custom services, counting only what you need. With a simple & interactive user interface, TalentOnLease developers build successful Python development projects, web apps & programs for individuals & enterprises.</p>
        <ul>
            <li>Custom development of web apps & web-pages.</li>
            <li>Strategic use of data analytics & visualisation.</li>
            <li>Offers you workflow automation.</li>
            <li>Provide better CX.</li>
            <li>High security & reliability.</li>
            <li>Extensive libraries & framework.</li>
            <li>Strong worldwide community.</li>
        </ul>
        <h3>Our Python Developer Skills</h3>
        <p>Python Developers at TalentOnLease are skilled in both technical & business acumen. This is how we boast about our picture perfect services to you. See some of them:</p>
        <ul>
        <li>Core Python</li>
        <li>Web Frameworks</li>
        <li>Front-End Tech</li>
        <li>Object Relational Mappers (ORM)</li>
        <li>Python Libraries</li>
        <li>Machine Learning and Artificial Intelligence</li>
        <li>Audio/Visual, Natural Language Processors</li>
        <li>Debug software</li>
        <li>Analytical skills</li>
        <li>General Python developer skills</li>
    </ul>
 </div>
 <div style="height: 150px;width: 75%; background-color: rgb(212, 212, 212);margin-left: 100px;padding: 10px;border-radius: 10px; margin-bottom: 10px;">
    <p style="font-size: 20px;">Python Developer Hiring resources</p>
    <p style="font-size: 14px;color: green;">Learn about cost factors</p>
    <button mat-raised-button (click)="openDialog()"  style="background-color: green; color: white;border-radius: 10px;border: none;">Hire talent</button>
    
    <!-- <div class="row email-box-main">
        <div class="col-md-6 mx-auto">
            <button mat-raised-button (click)="openDialog()" class="btn mb-15">Hire Python Developer Now</button>
        </div>
    </div> -->
 </div>
    
</section> 
<section class="section faqs-page-sec">
    <div class="container">
        <header class="gradient-title-head text-center">
            <small class="small-head">How we can help?</small>
            <h2 class="title-lg">Frequently Asked <span>Questions</span></h2>
        </header>
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/fullstackdeveloperimg/faq.png" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <div class="tabbing-faq-sec">
                    <div class="tabbing-body">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How soon can the developer join our team?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>The procedure takes between two and five days to finish. This time could be shortened
                                    with quick contract signing and interview turnaround.
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>How can you ensure the candidate will be
                                        productive?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>TalentOnLease is in charge of scheduling and keeping track of employee timesheets. In
                                    order to ensure effective engagement, each client has a PMO allocated to them that
                                    manages communications between deployed candidates and the client s SPOC.</p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Are they Freelancers?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Freelancers are not employed by us. In order to ensure seamless compliance, BGV is
                                    undertaken for all of the selected candidates. We work in a B2B model and make use
                                    of the bench strength of our dependable partners (Software Service Companies).</p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Can we hire Candidate on a permanent basis?</mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>Yes, you can hire them permanently mostly after 12 months of contact or as per the
                                    signed contarct between client and TalentOnLease.</p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="ready-get-started-section">
    <header class="gradient-title-head mb-30">
        <h3 class="title-lg text-center">Get Started
        </h3>
    </header>
    <p>
        We develop cornerstones for businesses with top-tier Python solutions. Hire custom & unique Python Developers from the top pool in India. 
    </p>
    <p>Flexible Working | Customised Solution | Unmatched Speed
    </p>
    <button mat-raised-button (click)="openDialog()" class="mat-focus-indicator btn btn-primary mat-button-base mb-15">Contact Us Now</button>
</section>
<div class="whatsapp-button fixed-button"><a href="https://wa.link/ixaedq" target="blank"><i class="fa fa-whatsapp"
    aria-hidden="true"></i></a>

</div>

<div class="callno-button fixed-button"><a href="tel:+919899240555" target="blank"><i class="fa fa-phone"
    aria-hidden="true"></i></a>

</div>