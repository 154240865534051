import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adminfooter',
  templateUrl: './adminfooter.component.html',
  styleUrls: ['./adminfooter.component.css']
})
export class AdminfooterComponent implements OnInit {

  hostName=location.hostname=='talentonlease.com'?'TalentOnLease': 'DigiRecruitnet';
  constructor() { }

  ngOnInit(): void {
  }

}
