import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { filter } from 'rxjs';
import { RenderService } from './_services/render.service';
declare var gtag:any;
import { Meta, Title } from '@angular/platform-browser';
import { RoutePartsService } from './_services/route-parts.service';
import { SharedService } from './_services/shared.service';
import { ChangeDetectionStrategy } from '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers:[RenderService],
})
export class AppComponent implements OnInit {
  pageTitle = '';
  appTitle="TalentOnLease";
  userDetail: any;
  isLoading:boolean;
  constructor(private meta:Meta,public title: Title,private router: Router,private routePartsService:RoutePartsService, private shared:SharedService,
    private activeRoute:ActivatedRoute,
    private sessionService: LocalStorageService,private service:RenderService) {
    this.userDetail = this.sessionService.retrieve('userDetail');
    const nvaEndEvents=router.events.pipe(filter((event)=>event instanceof NavigationEnd));
    nvaEndEvents.subscribe((event:any)=>{
      this.removeCanonicalLink();
      gtag('config', 'UA-135765070-1',{
        'page_location': document.location.origin + event.urlAfterRedirects,
        'page_title': document.title,
        'app_name': 'TOL',
        'screen_name': event.urlAfterRedirects,
        'page_path':event.urlAfterRedirects,
        'user_data':JSON.stringify(this.userDetail)
      });
    })
}
removeCanonicalLink(): void {
  const links = document.querySelectorAll('link[rel="canonical"]');
  links.forEach(link => {
    link.parentNode.removeChild(link);
  });
}
loaderSvc:any;
ngOnInit() {
  this.loaderSvc=this.shared.loaderSvc.subscribe((x)=>{
    setTimeout(() => {
    this.isLoading=(x==true?true:false);
    }, 20);
    });
   this.changePageTitle();
 }

changePageTitle() {
  this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange:any) => {
    const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
    if (!routeParts.length) {
      if(routeChange.url=='/'){
        return this.title.setTitle('AI Driven Tech Hiring Platform - TalentOnLease');
      }
      return this.title.setTitle('TalentOnLease');
    }
    // Extract title from parts;
    this.pageTitle = routeParts
                    .reverse()
                    .map((part) => part.title )
                    .reduce((partA, partI) => {return `${partA} > ${partI}`});
    //this.pageTitle += ` | ${this.appTitle}`;
    this.title.setTitle(this.pageTitle);
  });
}
}
